import { environment } from "src/environments/environment";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from "@angular/core";
import { authData } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { DataService } from "src/app/http/data.service";

@Component({
  selector: "app-navigation-drawer",
  templateUrl: "./navigation-drawer.component.html",
})
export class NavigationDrawerComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dataService: DataService
  ) {}

  open = false;
  _checks = authData("_checks");
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };
  checkPromise = this.dataService.fetchChecks();

  links = [
    {
      name: "home",
      title: "Dashboard",
      slug: "/",
      icon: "grid_view",
    },
    {
      name: "biodata",
      title: "Student Data",
      slug: "/bio-data",
      icon: "contact_page",
    },
    {
      name: "payment",
      title: "Payments",
      slug: "/payments",
      icon: "credit_card",
    },
    {
      name: "course",
      title: "Course Registration",
      slug: "/courses",
      icon: "menu_book",
    },
    {
      name: "result",
      title: "Results",
      slug: "/results",
      icon: "fact_check",
    },
    {
      name: "appointment",
      title: "Appointment",
      slug: "/appointment",
      icon: "book_online",
    },
    {
      name: "election",
      title: "Election",
      slug: "/election",
      icon: "how_to_vote",
    },
    {
      name: "accommodation",
      title: "Accommodation",
      slug: "/accommodation",
      icon: "house",
    },
    {
      name: "application",
      title: "Student Applications",
      slug: "/application",
      icon: "note_alt",
    },
  ];
  xLinks: Link[] = [];

  async ngOnInit(): Promise<void> {
    this.dataService.getData().subscribe((data) => {
      this.open = data;
    });

    const navItems: any = [];
    const navLinks: any = environment.nav_links;
    Object.keys(navLinks).forEach((x: any) => {
      if (navLinks[x]) navItems.push(x);
    });

    this.links = this.links.filter((el) => navItems.includes(el.name));

    this.checkPromise
      .then((result) => {
        this._checks = result.Data;

        if (this._checks.EnableWebTest)
          this.xLinks.push({
            title: "Webtest",
            isImpata: false,
            icon: "/assets/logos/webtest.png",
          });
        if (this._checks.EnableImpata)
          this.xLinks.push({
            title: "Impata",
            isImpata: true,
            icon: "/assets/logos/impata.png",
          });
      })
      .catch((error) => {});
  }

  external(isImpata: boolean) {
    var baseUrl = `${environment.BASE_URL}/users/impata`;

    if (!isImpata) baseUrl = `${environment.BASE_URL}/users/webtest`;

    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          window.open(res.Data, "_blank");
        } else {
          this.router.navigateByUrl(`/#${res.Message}`);
        }
      },
      error: (err: any) => {
        this.router.navigateByUrl(`/#${err.error.Message}`);
      },
    });
  }
}

interface Link {
  title: string;
  isImpata: boolean;
  icon: string;
}
