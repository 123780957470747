import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-change-of-programme-index",
  templateUrl: "./application-change-of-programme-index.component.html",
  styleUrls: ["./application-change-of-programme-index.component.scss"],
})
export class ApplicationChangeOfProgrammeIndexComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  notification = {};
  downloader: any = null;

  fakeLoader = false;
  loading = false;
  StudentProfileView = false;

  links: Link[] = [];

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    this.links.push({
      title: "Submit Change of Programme Application",
      slug: "/application/changeofprogramme/submit",
      icon: "/assets/icons/visa.png",
      text: "Add a line or two here that explains what this card does",
    });

    const message: string = location.hash.slice(1);
    if (message) {
      this.notification = {
        message: message.replace(/\+/g, " "),
      };
    }
  }

  printChangeOfProgramme() {
    if (this.loading) return;

    const _checks = authData("_checks");
    const { ChangeOfProgrammeApplicationSession } = _checks;
    if (!ChangeOfProgrammeApplicationSession) {
      this.notification = {
        message: "You are not allowed to print your change of programme application",
        error: true,
      };
      return;
    }

    this.loading = true;
    this.notification = {};
    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/users/changeofprogrammeapplicationletter`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.downloader = {
            loading: false,
            name: res.Data.Name,
            url: `data:application/octet-stream;base64,${res.Data.Data}`,
          };
        } else {
          this.downloader = null;
          this.notification = { error: true, message: res.Message };
          this.loading = false;
        }
      },
      error: (err) => {
        this.downloader = null;
        this.notification = errorHandler(err);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}

interface Link {
  title: string;
  slug: string;
  icon: string;
  text: string;
}
