import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-siwes",
  templateUrl: "./application-siwes.component.html",
  styleUrls: ["./application-siwes.component.scss"],
})
export class ApplicationSiwesComponent implements OnInit {
  @ViewChild("goUp", { static: true }) contentPage!: ElementRef;
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  response = {};

  company = "";
  state = "";

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/biodata/siwesform`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
        } else {
          if (res.Data && res.Data.Completed)
            this.router.navigateByUrl(
              `/application/siwesview#You+have+completed+your+siwes+form+and+can+no+longer+edit,+click+on+'Print+Siwes+Form'`
            );
          this.notification = errorHandler(res);
        }
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }

  showUp() {
    this.contentPage.nativeElement.scrollIntoView();
  }
  submitForm() {
    this.loading = true;
    this.notification = {};
    this.response = {
      CompanyAddress: this.payload.CompanyAddress,
      CompanyID: this.company,
      ContactPerson: this.payload.ContactPerson,
      ContactPersonPhoneNumber: this.payload.ContactPersonPhoneNumber,
      Session: this.payload.Session,
      Supervisor: this.payload.Supervisor,
      SupervisorEmail: this.payload.SupervisorEmail,
      SupervisorPhoneNumber: this.payload.SupervisorPhoneNumber,
      ContactPersonEmail: this.payload.ContactPersonEmail,
      State: this.state,
      StartDate: this.payload.StartDate,
      EndDate: this.payload.EndDate,
    };
    const baseUrl = `${environment.BASE_URL}/biodata/savesiwesform`;
    this.httpClient.post(baseUrl, this.response, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(`/application/siwesview`);
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
    this.showUp();
  }
}
