<app-dashboard-layout [notDashboard]="true" [loading]="loading || refreshing">
  <section class="bg-white border rounded-md p-4">
    <header class="border-b flex text-lg w-full pb-4 gap-8 md:text-xl">
      Election Application
    </header>

    <div
      *ngIf="refreshing"
      class="max-w-screen-md grid pb-4 gap-4 grid-cols-1 md:gap-8 md:grid-cols-2"
    >
      <div
        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]"
        class="border rounded-md bg-gray-300 h-20 animate-pulse"
      ></div>
    </div>

    <app-notification-alert [data]="notification"></app-notification-alert>

    <div *ngIf="payload.questions">
      <form
        ngNativeValidate
        *ngIf="!refreshing"
        id="biodata-medical-history"
        (ngSubmit)="submitApplication()"
        class="max-w-screen-md grid gap-8 grid-cols-1"
      >
        <section class="">
          <div class="font-medium text-grey-darker text-sm pt-4 pb-2 block">
            Select a position
          </div>

          <div
            class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <select
              required
              name="position"
              [(ngModel)]="payload.position"
              class="w-full p-1 focus:outline-none"
            >
              <option disabled value="" defaultSelected>Select option</option>
              <option
                *ngFor="let position of positions"
                [value]="position.PositionID"
              >
                {{ position.PositionName }}
              </option>
            </select>
          </div>
        </section>

        <label
          class="block"
          *ngFor="let question of payload.questions; let i = index"
        >
          <div class="font-medium text-grey-darker text-sm pb-2">
            {{ question.Question }}
          </div>
          <div
            class="border border-primary-lighter rounded flex text-sm w-full py-2 px-3 text-gray-700 justify-between items-center appearance-none focus:outline-none focus-within:border-primary-light"
          >
            <div>{{ payload.questions[i].Answer ? "Yes" : "No" }}</div>
            <input
              type="checkbox"
              [name]="question.ID"
              (change)="toggleCheckBox(i)"
            />
          </div>
        </label>

        <nav class="flex flex-col py-6 gap-4 justify-center">
          <app-button-primary
            type="submit"
            [disabled]="loading"
            class="mx-auto max-w-screen-sm w-full"
          >
            Submit Application
          </app-button-primary>

          <app-notification-alert
            [data]="notification"
          ></app-notification-alert>
        </nav>
      </form>
    </div>
  </section>
</app-dashboard-layout>
