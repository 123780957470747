<app-dashboard-layout [notDashboard]="true" [loading]="refreshing">
  <header class="text-lg w-full pb-6 gap-8 md:text-xl">
    Election Application
    <div class="text-sm text-gray-600">
      Please select an election to apply for candidacy.
    </div>
  </header>

  <app-notification-alert [data]="notification"></app-notification-alert>

  <section *ngIf="refreshing">
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div
      class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"
    ></div>
  </section>

  <app-no-content
    *ngIf="!elections.length && !refreshing"
    caption="No election application"
  >
  </app-no-content>

  <div class="flex flex-col gap-4">
    <a
      *ngFor="let item of elections"
      (click)="electionCandidate(item.ID)"
      class="bg-white rounded-md cursor-pointer border-2 p-4 transform duration-200 block group md:p-6 hover:border-primary hover:bg-primary/5 hover:scale-95"
    >
      <div
        class="font-medium text-base pb-1 capitalize group-hover:text-primary"
      >
        {{ item.ElectionName }}
      </div>
      <section class="flex gap-8 justify-between items-end">
        <div class="flex flex-col text-sm text-gray-500 gap-2">
          <div class="flex gap-2 items-center">
            <div class="text-sm material-icons md:text-lg">schedule</div>
            <div class="text-xs md:text-base">
              {{ item.StartTime | date : "MMM d, y, h:mm a" }}
            </div>
          </div>
          <div class="flex gap-2 items-center">
            <div class="text-sm material-icons md:text-lg">
              hourglass_bottom
            </div>
            <div class="text-xs md:text-base">
              {{ item.StopTime | date : "MMM d, y, h:mm a" }}
            </div>
          </div>
        </div>

        <button
          class="bg-primary rounded font-medium text-white text-sm py-2 px-2 md:px-4"
        >
          Apply Now
        </button>
      </section>
    </a>
  </div>
</app-dashboard-layout>
