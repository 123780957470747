import { HttpClient, HttpHeaders } from "@angular/common/http";
import { authData, saveAuth } from "src/app/util/auth";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { DataService } from "src/app/http/data.service";

@Component({
  selector: "app-student-info-header",
  templateUrl: "./student-info-header.component.html",
})
export class StudentInfoHeaderComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dataService: DataService
  ) { }

  _auth = authData();
  _courses = authData("_courses");
  _checks = authData("_checks");

  items = [
    {
      title: this._auth?.Level,
      value: "Year",
    },
    {
      title: this._auth?.Semester,
      value: "Semester",
    },
    {
      title: this._courses?.Courses?.length || 0,
      value: "Courses",
    },
    {
      title:
        this._courses?.Courses?.reduce(
          (x: number, y: any) => x + +y?.CourseUnits,
          0
        ) || 0,
      value: "Units",
    },
  ];

  ngOnInit(): void {
    if (Object.keys(this._checks).length === 0) {
      this.accommodationChecks();
      this.sliderData();
      this.requirementData();
      this.fetchCourse();
      this.fetchChecks();
      //this.refreshPayment();
    }
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${authData().Token}`,
    }),
  };

  clearMessage() {
    const _auth = authData();
    saveAuth({ ..._auth, NewMatricNumber: null });
    this._auth = authData();
  }

  fetchCourse() {
    const baseUrl = `${environment.BASE_URL}/courses/currentCourses`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => saveAuth(res?.Data || [], "_courses"),
      error: (err) => saveAuth([], "_courses"),
    });
  }

  fetchChecks() {
    const data = this.dataService.fetchChecks();
    data
      .then((result) => {
        saveAuth(result, "_checks");
      })
      .catch((err) => {
        saveAuth({}, "_checks");
      });
  }
  accommodationChecks() {
    const data = this.dataService.accommodationChecks();
    data
      .then((result) => {
        saveAuth(result, "_accommodation");
      })
      .catch((err) => {
        saveAuth({}, "_accommodation");
      });
  }
  sliderData() {
    const data = this.dataService.fetchSlider();
    data
      .then((result) => {
        saveAuth(result, "_slider");
      })
      .catch((err) => {
        saveAuth({}, "_slider");
      });
  }
  requirementData() {
    const baseUrl = `${environment.BASE_URL}/users/requirements`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => saveAuth(res.Data, "_requirements"),
      error: (err) => saveAuth({}, "_requirements"),
    });
  }
  refreshPayment() {
    const baseUrl = `${environment.BASE_URL}/payments/refereshStatus`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe();
  }
}
