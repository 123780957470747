<div class="fixed z-10 inset-0 overflow-y-auto pb-8 sm:pb-0">
  <div class="flex items-center justify-center min-h-screen">
    <div class="bg-white rounded-lg p-8 w-full sm:w-1/2">
      <h1 class="text-2xl font-bold mb-4">
        Student Sessional Contact Information Update
      </h1>
      <p class="mb-4">
        Please confirm that the information below is correct: </p>
      <div class="font-medium text-grey-darker text-sm mb-2 block">
        Telephone *
        <input [(ngModel)]="_auth?.Student.Telephone" [disabled]="!isEditable" placeholder="Telephone"
          class="border border-gray-300 p-2 mb-4 w-full" />
      </div>

      <div class="font-medium text-grey-darker text-sm mb-2 block">
        Mobile Number *
        <input [(ngModel)]="_auth?.Student.Mobile" [disabled]="!isEditable" placeholder="Mobile Number"
          class="border border-gray-300 p-2 mb-4 w-full" />
      </div>
      <div class="font-medium text-grey-darker text-sm mb-2 block">
        Email Address *
        <input [(ngModel)]="_auth?.Student.AlternateEmail" [disabled]="!isEditable" placeholder="Email Address"
          class="border border-gray-300 p-2 mb-4 w-full" />
      </div>
      <div class="font-medium text-grey-darker text-sm mb-2 block">
        Contact Address *
        <textarea [(ngModel)]="_auth?.Student.Address" [disabled]="!isEditable" placeholder="Contact Address"
          class="border border-gray-300 p-2 mb-4 w-full rounded-md"></textarea>
      </div>
      <button (click)="ok()"
        class="bg-green-500 text-white rounded-lg py-2 px-4 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50">
        Ok
      </button>
      <button *ngIf="!isEditable" (click)="toggleEditable()" class="ml-auto sm:ml-5 bg-blue-500 text-white rounded-lg py-2 px-4 mr-2 hover:bg-blue-700 focus:outline-none focus:ring-2
        focus:ring-blue-600 focus:ring-opacity-50 mt-2 sm:mt-0">
        Edit
      </button>

    </div>
  </div>
</div>