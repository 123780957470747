<app-dashboard-layout [dialog]="dialog">
  <app-notification-alert [data]="notification"></app-notification-alert>
  <section
    class="grid gap-3 pt-3 pb-20 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
  >
    <div class="col-span-2 sm:grid-cols-1">
      <app-dashboard-card
        routerLink="/courses"
        title="Courses"
        icon="/assets/icons/document.png"
        caption="Add a line or two here that explains what this card does"
        [requirement]="true"
      ></app-dashboard-card>
    </div>
     <app-dashboard-card 
     (click)="refreshPayment()" 
     title="Refresh Payment Status" 
     icon="/assets/icons/refresh.png"
     ></app-dashboard-card>
    <app-dashboard-card
      *ngFor="let link of links"
      [routerLink]="link.slug"
      [title]="link.title"
      [icon]="link.icon"
      [caption]="link.text"
    >
    </app-dashboard-card>
    <div
      class="col-span-2 grid-cols-2 xl:col-end-5 hidden xl:block lg:block"
      *ngIf="payload"
    >
      <mdb-carousel
        class="carousel slide carousel-fade"
        [controls]="true"
        [indicators]="true"
        [dark]="true"
      >
        <mdb-carousel-item *ngFor="let item of payload">
          <img [src]="item.ImageURL" class="d-block w-100" alt="..." />
          <div
            class="carousel-caption d-none d-md-block text-white text-xs relative font-extrabold"
          >
            <h5>{{ item.Subject }}</h5>
            <p>{{ item.ShortDescription }}</p>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </section>
  <div *ngIf="showPopup"
    class="bg-opacity-50 backdrop-filter backdrop-blur-sm w-full h-screen fixed z-50 top-0 left-0 fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen">
      <app-dashboard-popup (okClicked)="onOkClicked($event)"></app-dashboard-popup>
    </div>
  </div>
</app-dashboard-layout>
