<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <div #goUp></div>
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-4 gap-8 md:text-xl">
            Student Exam Confirmation
        </header>
        <app-notification-alert class="pt-4" [data]="notification"></app-notification-alert>
        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <form ngNativeValidate *ngIf="!skeletonLoader" id="referral-application"
            class="max-w-screen-sm grid gap-8 grid-cols-1" (ngSubmit)="submitForm()">

            <label class="block pt-3">
                <div class="font-medium text-grey-darker text-sm mb-2 block">
                    Examination*:
                </div>
                <div class="pt-3">
                    <div class="bg-white border border-primary rounded-lg w-full p-2">
                        <select required [(ngModel)]="entryexam" class="w-full p-2 focus:outline-none"
                            name="examination" (change)="getSubjects()">
                            <option disabled value="">Select an Examination</option>
                            <option *ngFor="let entryexam of entryexams" value="{{entryexam.EntryExaminationID}}">
                                {{ entryexam.EntryExaminationID }}
                            </option>
                        </select>
                    </div>
                </div>
            </label>

            <div *ngIf="payload.EntryExaminationPeriodsDTO">
                <label class="block pt-3">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Examination Period*:
                    </div>
                    <div class="py-3">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select required [(ngModel)]="examperiod" class="w-full p-2 focus:outline-none"
                                name="examinationperiod">
                                <option disabled value="">Select an Examination Period</option>
                                <option *ngFor="let examperiod of payload.EntryExaminationPeriodsDTO"
                                    value="{{examperiod.ExaminationPeriod}}">
                                    {{ examperiod.ExaminationPeriod }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>

                <label class="block pt-3">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Examination Year*:
                    </div>
                    <div class="py-3">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select required [(ngModel)]="examyear" class="w-full p-2 focus:outline-none"
                                name="examinationyear">
                                <option disabled value="">Select an Examination Year</option>
                                <option *ngFor="let examyear of examyears" value="{{examyear}}">
                                    {{ examyear }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>

                <label class="block pb-2">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Examination Number*
                    </div>
                    <input required type="text" name="examnmber" [(ngModel)]="examnumber"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>

                <label class="block pb-2">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Centre/School Name
                    </div>
                    <input type="text" name="schoolname" [(ngModel)]="schoolname"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>


                <h2 class="py-3">Enter Results for All Subjects taken</h2>

                <div class="grid grid-cols-2 gap-4" *ngFor="let index of range(payload.MaximumSubject); let i = index">
                    <div class="py-3">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select required [(ngModel)]="subjectSelections[i]" class="w-full p-2 focus:outline-none"
                                name="subject-{{i}}">
                                <option disabled value="">Select a subject</option>
                                <option *ngFor="let subject of payload.SubjectRequirementsDTO"
                                    value="{{subject.SubjectID}}">
                                    {{ subject.SubjectName ? subject.SubjectName : subject.SubjectID }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="py-3">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select required [(ngModel)]="gradeSelections[i]" class="w-full p-2 focus:outline-none"
                                name="grade-{{i}}">
                                <option disabled value="">Select a grade</option>
                                <option *ngFor="let grade of payload.GradesDTO" value="{{grade.Grade}}">
                                    {{ grade.Grade }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <nav class="flex flex-col py-2 gap-4 justify-center">
                <app-button-primary type="submit" [disabled]="loading" class="mx-auto max-w-screen-sm w-full">
                    Submit
                </app-button-primary>
            </nav>
        </form>
    </section>
</app-dashboard-layout>