import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-course-evaluation-questionnaire",
  templateUrl: "./course-evaluation-questionnaire.component.html",
  styleUrls: ["./course-evaluation-questionnaire.component.scss"],
})
export class CourseEvaluationQuestionnaireComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  _auth = authData();

  courses: any = {};
  selectedCourse = "";
  selectedLecturer = "";
  option: any = {};
  remark: any = {};
  maxCourses: number = 0;
  addedCourses: string[] = [];
  payload: any = [];

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/courses/evaluationquestionnaire`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.courses = res.Data;
          this.maxCourses =
            this.courses.MinimumCourseEvaluationReviewsPerStudent;
        } else {
          this.notification = errorHandler(res);
        }
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }
  submitForm() {
    const baseUrl = `${environment.BASE_URL}/courses/evaluationquestionnaire`;
    this.httpClient.post(baseUrl, this.payload, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(
            "/courses?#Thanks+for+Completing+the+Questionnaire,+Please+proceed+to+Generate+Docket"
          );
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
  onCourseSelected() {
    if (this.selectedCourse) {
      this.selectedLecturer = "";
    }
  }

  getLecturersForCourse(courseCode: string): any[] {
    const selectedCourse = this.courses.CourseDetails.find(
      (course: { CourseCode: string }) => course.CourseCode === courseCode
    );
    return selectedCourse ? selectedCourse.StaffDetails : [];
  }
  addCourse(selectedCourse: string) {
    if (!selectedCourse || !this.selectedLecturer) {
      this.notification = {
        error: true,
        message: "Please select a course or lecturer",
      };
      return;
    }
    if (
      Object.keys(this.option).length == 0 ||
      Object.keys(this.remark).length == 0
    ) {
      this.notification = {
        error: true,
        message: "Please select an option or fill the comments",
      };
      return;
    }
    if (this.addedCourses.length < this.maxCourses) {
      this.addedCourses.push(selectedCourse);

      const payload: {
        CourseCode: string;
        EvaluationQuestionnaireResult: EvaluationQuestionnaireResult[];
      } = {
        CourseCode: this.selectedCourse,
        EvaluationQuestionnaireResult: [],
      };

      for (const questionId of Object.keys(this.option)) {
        const result: EvaluationQuestionnaireResult = {
          QuestionId: questionId,
          LecturerId: this.selectedLecturer,
          Remark: "",
          SelectedOptionId: this.option[questionId],
          isRemark: false,
        };
        payload.EvaluationQuestionnaireResult.push(result);
      }

      for (const questionId of Object.keys(this.remark)) {
        const result: EvaluationQuestionnaireResult = {
          QuestionId: questionId,
          LecturerId: this.selectedLecturer,
          Remark: this.remark[questionId],
          SelectedOptionId: "",
          isRemark: true,
        };
        payload.EvaluationQuestionnaireResult.push(result);
      }
      this.payload.push(payload);

      this.selectedCourse = "";
      this.selectedLecturer = "";
      this.option = {};
      this.remark = {};
    }
    this.notification = {};
  }

  removeCourse() {
    const index = this.addedCourses.length - 1;
    if (index !== -1) {
      this.addedCourses.splice(index, 1);
      this.payload.splice(index, 1);
    }
  }
  isSubmitDisabled(): boolean {
    return this.addedCourses.length !== this.maxCourses;
  }
}

interface EvaluationQuestionnaireResult {
  QuestionId: string;
  LecturerId: string;
  Remark: string;
  SelectedOptionId: string;
  isRemark: boolean;
}
