  <section
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-title"
    class="inset-0 z-10 fixed overflow-y-auto"
  >
    <div class="flex h-screen text-center px-4 items-center justify-center">
      <div
        aria-hidden="true"
        class="bg-gray-500 bg-opacity-75 inset-0 transition-opacity fixed"
      ></div>
      
      <form ngNativeValidate (ngSubmit)="submitAppointment()"
        class="bg-white p-8 rounded-lg h-fit shadow-xl text-left w-full transform transition-all overflow-hidden sm:max-w-screen-sm sm:my-8"
      >  
        <em (click)="close()" class="text-error cursor-pointer" style="position: relative; left: 90%;">close</em>
        <div>
            <p class="font-semibold md:text-xl text-header mt-5">Edit Appointment</p>
            <p class="font-normal md:text-lg text-standard">Reschedule an appointment with a department.</p>
        </div>
        <app-notification-alert [data]="notification"></app-notification-alert>        
      
      <label class="font-normal md:text-sm pt-2 text-standard">Select Appointment</label>
      <select class="md:text-xs block w-full border border-inputborder px-3 py-2 text-gray-700 rounded leading-tight">
          <option selected disabled>{{selectedAppointment.Event}}</option>                          
      </select>

      <label class="font-normal md:text-sm pt-2 text-standard">Select Session</label>
      <select [(ngModel)]="selectedSessionID" class="md:text-xs block w-full border border-inputborder px-3 py-2 text-gray-700 rounded leading-tight">
          <option value="" hidden selected disabled>Select Session</option>
          <option *ngFor="let session of availableSession" [ngValue]="session.SessionID" >
              {{session.SessionID}}
          </option>
      </select>
      
      <nav class="flex flex-col py-2 gap-4 justify-center pt-5">
          <app-button-primary [disabled]="loading || availableSession.length < 1" type="submit" class="mx-auto">
              SCHEDULE APPOINTMENT
          </app-button-primary>
      </nav>      
               
      </form>
    </div>
  </section>  