<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
            Examination Results
        </header>

        <app-notification-alert [data]="notification"></app-notification-alert>
        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <div *ngIf="!skeletonLoader" class="overflow-x-auto relative shadow-md sm:rounded-lg">
            <a class="text-center bg-primary/90 text-sm text-white float-right m-3 p-2"
                [routerLink]="['/application/examination/modify']">Add a new Examination Result
            </a>
            <table class="w-full text-sm text-left">
                <thead class="text-xs text-white uppercase bg-primary/90">
                    <tr>
                        <th scope="col" class="py-3 px-6">
                            Examination Name
                        </th>
                        <th scope="col" class="py-3 px-6">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let req of payload" class="text-gray-700 text-sm">
                        <th scope="row" class="py-4 px-6 font-medium whitespace-nowrap">
                            {{req.ExaminationName}}
                        </th>
                        <td class="py-4 px-6 font-medium whitespace-nowrap">
                            <p class="pb-0 mb-0">
                                <a class="text-center text-sm text-primary hover: text-primary-200">Edit
                                </a>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <label class="px-6 py-3 text-sm">Click Continue If You Have No Edit To Make</label>
            <nav class="flex flex-col py-2 gap-4 justify-center">
                <a class="mx-auto max-w-screen-sm w-1/4" [routerLink]="['/application/examination/confirmation']">
                    Continue
                </a>
            </nav>
        </div>
    </section>
</app-dashboard-layout>