import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-election-candidate-application",
  templateUrl: "./election-candidate-application.component.html",
})
export class ElectionCandidateApplicationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient
  ) {}

  loading = false;
  refreshing = true;
  _auth = authData();
  notification = {};

  questions: any = [];
  positions: any = [];
  payload: any = { position: "", questions: [] };
  electionId: any;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._auth.Token,
    }),
  };

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.refreshing = true;
      this.electionId = params.id;
      const baseUrl = `${environment.BASE_URL}/elections/electionApplications?electionId=${params.id}`;

      this.httpClient.get(baseUrl, this.httpOptions).subscribe({
        next: (res: any) => {
          this.positions = res.Data.ElectionPositionDTO;
          this.payload.questions = res.Data.ElectionApplicationFormQuestionsDTO;
        },
        complete: () => {
          this.refreshing = false;
        },
      });
    });
  }

  toggleCheckBox(index: number) {
    this.payload.questions[index].Answer =
      !this.payload.questions[index].Answer;
  }

  submitApplication() {
    this.loading = true;
    const payload = {
      ElectionApplicationFormQuestionsDTO: [...this.payload.questions],
      ElectionID: this.electionId,
      PositionID: this.payload.position
    };

    const baseUrl = `${environment.BASE_URL}/elections/saveapplication`;

    this.httpClient
      .post(baseUrl, payload, this.httpOptions)
      .subscribe({
        next: (res: any) => {
          if (res.Success) {
            this.router.navigateByUrl(
              "election#Your+election+application+was+successful"
            );
          } else {
            this.notification = errorHandler({
              error: true,
              message: res.Message,
            });
          }
        },
        error: (err) => {
          this.notification = errorHandler(err);
        },
      })
      .add(() => {
        this.loading = false;
      });
  }
}
