import { authData } from "src/app/util/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { errorHandler } from "src/app/util/errorHandler";

@Component({
  selector: "app-results-print",
  templateUrl: "./results-print.component.html",
})
export class ResultsPrintComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}
  session = "";
  semester = "";
  sessions: any = [];
  semesters: any = [];
  notification: any = {};
  noContentFound: boolean = false;

  skeletonLoader = true;
  downloader: any = null;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/results/semesterSession`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.skeletonLoader = false;
          this.sessions = res.Data.Sessions;
          this.semesters = res.Data.Semesters;
        } else {
          this.downloader = null;
          this.notification = { error: true, message: res.Message };
        }
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }

  printResult() {
    this.notification = {};
    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/results/printResults?session=${this.session}&semester=${this.semester}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.downloader = {
            loading: false,
            name: res.Data.Name,
            url: `data:application/octet-stream;base64,${res.Data.Data}`,
          };
        } else {
          this.downloader = null;
          this.notification = { error: true, message: res.Message };
        }
      },
      error: (err) => {
        this.downloader = null;
        this.notification = errorHandler(err);
      },
    });
  }
}
