import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-student-examinations-modify",
  templateUrl: "./application-student-examinations-modify.component.html",
  styleUrls: ["./application-student-examinations-modify.component.scss"],
})
export class ApplicationStudentExaminationsModifyComponent implements OnInit {
  @ViewChild("goUp", { static: true }) contentPage!: ElementRef;
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  entryexams: any = {};
  entryexam: string = "";
  examyears: any = {};
  response = {};
  subjects: any = {};
  examperiod: string = "";
  examyear: string = "";
  examnumber: string = "";
  schoolname: string = "";
  subjectSelections: string[] = [];
  gradeSelections: string[] = [];

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = false;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const _checks = authData("_checks");
    const { EntryCompleted } = _checks;
    if (EntryCompleted) {
      this.notification = {
        message: "You have completed your form and you can no longer edit",
        error: true,
      };
      return;
    }

    const baseUrl = `${environment.BASE_URL}/biodata/studententryexamrequirements`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.entryexams = res.Data.EntryExaminations;
          this.examyears = res.Data.Years;
        } else {
          this.notification = errorHandler(res);
        }
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }

  getSubjects() {
    this.loading = true;
    const baseUrl = `${environment.BASE_URL}/biodata/studententrysubjectperexam?entryExaminationId=${this.entryexam}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
    this.showUp();
  }

  showUp() {
    this.contentPage.nativeElement.scrollIntoView();
  }
  range(length: number): number[] {
    return new Array(length);
  }
  submitForm() {
    this.loading = true;
    this.notification = {};

    this.response = {
      Confirmation: false,
      StudentExaminationDTO: {
        StudentApplicantEntryExaminationID: 0,
        SchoolName: this.schoolname,
        ExaminationDate: this.examperiod,
        ExaminationName: this.entryexam,
        ExaminationNumber: this.examnumber,
        ExaminationYear: this.examyear,
        SubjectID: this.subjectSelections,
        Grade: this.gradeSelections,
      },
    };

    const baseUrl = `${environment.BASE_URL}/biodata/savestudententryExam`;
    this.httpClient.post(baseUrl, this.response, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(
            `/application/examination/confirmation#Your+Examination+has+been+added+successfully`
          );
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
}
