import { Component, OnInit } from "@angular/core";
import { authData } from "src/app/util/auth";

@Component({
  selector: "app-election-index",
  templateUrl: "./election-index.component.html",
})
export class ElectionIndexComponent implements OnInit {
  notification = {};
  links: Link[] = [];

  ngOnInit(): void {
    const message: string = location.hash.slice(1);

    if (message)
      this.notification = {
        message: message.replace(/\+/g, " "),
      };

    const { IsEligibleForRunningElection, IsEligibleForElectionApplication } =
      authData("_checks");

    if (IsEligibleForRunningElection)
      this.links.push({
        title: "Running Elections",
        slug: "/election/vote",
        icon: "/assets/icons/ballot-box.png",
        text: "Add a line or two here that explains what this card does",
      });
    if (IsEligibleForElectionApplication)
      this.links.push({
        title: "Election Applications",
        slug: "/election/application",
        icon: "/assets/icons/document.png",
        text: "Add a line or two here that explains what this card does",
      });
  }
}

interface Link {
  title: string;
  slug: string;
  icon: string;
  text: string;
}
