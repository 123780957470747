<div (click)="toggleForm()" class="h-full">
  <ng-content></ng-content>
</div>

<section
  role="dialog"
  *ngIf="show"
  aria-modal="true"
  aria-labelledby="modal-title"
  class="inset-0 z-10 fixed overflow-y-auto"
>
  <div class="flex h-screen text-center px-4 items-center justify-center">
    <div
      aria-hidden="true"
      class="bg-gray-500 bg-opacity-75 inset-0 transition-opacity fixed"
    ></div>

    <form
      class="bg-white rounded-lg flex flex-col h-5/6 shadow-xl text-left w-full transform transition-all justify-between align-bottom overflow-hidden sm:max-w-screen-sm sm:my-8"
    >
      <app-loading-indicator *ngIf="loading"></app-loading-indicator>

      <header class="border-b bg-gray-50 p-8 pb-4">
        <h1 class="text-sm pb-1 md:text-lg">
          Select courses to add to your course list
        </h1>

        <nav
          class="border-primary flex border-b-2 py-1 gap-4 justify-between items-center"
        >
          <input
            name="search"
            [(ngModel)]="filter"
            placeholder="Search for a course"
            class="bg-transparent w-full p-1 focus:outline-none"
          />
          <button class="text-primary material-icons">search</button>
        </nav>
      </header>

      <section *ngIf="skeletonLoader" class="h-full p-8">
        <div
          *ngFor="let i of [1, 2, 3, 4, 5, 6]"
          class="border rounded-md bg-gray-300 h-12 mb-2 animate-pulse"
        ></div>
      </section>

      <ng-template [ngIf]="!skeletonLoader">
        <section
          class="h-full overflow-y-auto"
          *ngIf="notification.message || filterCourses().length < 1"
        >
          <app-no-content
            [icon]="
              notification.message ? '/assets/images/error-message.png' : ''
            "
            [caption]="
              notification.message ||
              (filter ? 'Nothing found for: ' + filter : 'No courses found')
            "
          ></app-no-content>
        </section>

        <section
          *ngIf="!notification.message"
          class="flex flex-col flex-grow p-8 pt-4 gap-4 overflow-y-auto"
        >
          <label
            *ngFor="let course of filterCourses()"
            class="rounded-md border-2 p-4 transform duration-200 justify-between group hover:bg-primary-lighter hover:border-primary hover:scale-105"
          >
            <div class="flex items-center justify-between">
              <div>
                <div
                  class="font-medium text-base pb-4 capitalize group-hover:text-primary"
                >
                  {{ course.CourseTitle }}
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2">
                  <div
                    class="flex text-xs text-gray-500 gap-2 uppercase hover:border-primary"
                  >
                    <div class="whitespace-nowrap">
                      {{ course.CourseCode }}
                    </div>
                    &bullet;
                    <div class="whitespace-nowrap">
                      {{ course.CourseUnits }} UNITS
                    </div>
                    &bullet;
                    <div class="whitespace-nowrap">
                      {{ course?.Compulsory ? "Compulsory" : "Elective" }}
                    </div>
                  </div>
                </div>
              </div>

              <input
                [name]="course.CourseCode"
                [id]="course.CourseCode"
                (click)="clickCourse(course.CourseCode, course.Compulsory)"
                [checked]="payload.includes(course.CourseCode)"
                type="checkbox"
              />
            </div>
          </label>
        </section>
      </ng-template>

      <footer class="border-t flex bg-gray-50 p-6 justify-between">
        <app-button-primary
          [outline]="true"
          (click)="toggleForm()"
        >
          Cancel
        </app-button-primary>

        <app-button-primary
          (click)="saveCourses()"
          [disabled]="loading || !payload.length || this.notification.error"
        >
          Save
        </app-button-primary>
      </footer>
    </form>
  </div>
</section>
