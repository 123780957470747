<app-dashboard-layout
  [notDashboard]="false"
  [downloader]="downloader"
  [loading]="loading || skeletonLoader"
>
  <section class="bg-white border rounded-md p-4">
    <header class="border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
      Course Evaluation Questionnaire:
    </header>

    <section *ngIf="skeletonLoader">
      <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
      <div
        class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"
      ></div>
    </section>

    <form
      ngNativeValidate
      *ngIf="!skeletonLoader"
      id="referral-application"
      class="grid gap-4 w-full"
      (ngSubmit)="submitForm()"
    >
      <app-notification-alert
        class="pt-4"
        [data]="notification"
      ></app-notification-alert>

      <div class="grid gap-4 w-full">
        <h2>Important Notice</h2>
        <label class="ml-8 font-bold"
          >You are required to review
          {{ courses.MinimumCourseEvaluationReviewsPerStudent }} Courses.
        </label>
        <label
          class="font-bold"
          [innerHtml]="courses.CourseEvaluationQuestionnaireInstructions"
        ></label>

        <div class="ml-8 flex flex-wrap">
          <label
            *ngFor="let course of addedCourses"
            class="bg-green-500 text-white rounded-md p-2 mr-2 mb-2"
          >
            {{ course }}
          </label>
        </div>

        <div class="ml-8 grid gap-8 grid-cols-3">
          <label *ngIf="isSubmitDisabled()" class="block">
            <div class="py-8">
              <div class="bg-white border border-primary rounded-lg w-full p-2">
                <select
                  required
                  [(ngModel)]="selectedCourse"
                  (change)="onCourseSelected()"
                  class="w-full p-2 focus:outline-none"
                  name="course"
                >
                  <option disabled value="">Select a Course</option>
                  <option
                    *ngFor="let course of courses.CourseDetails"
                    [value]="course.CourseCode"
                  >
                    {{ course.CourseTitle }} ({{ course.CourseCode }})
                  </option>
                </select>
              </div>
            </div>
          </label>

          <div *ngIf="selectedCourse">
            <label class="block">
              <div class="py-8">
                <div
                  class="bg-white border border-primary rounded-lg w-full p-2"
                >
                  <select
                    required
                    [(ngModel)]="selectedLecturer"
                    class="w-full p-2 focus:outline-none"
                    name="lecturer"
                  >
                    <option disabled value="">Select a Lecturer</option>
                    <option
                      *ngFor="
                        let lecturer of getLecturersForCourse(selectedCourse)
                      "
                      [value]="lecturer.StaffId"
                    >
                      {{ lecturer.FullName }}
                    </option>
                  </select>
                </div>
              </div>
            </label>
          </div>
        </div>

        <div
          *ngIf="selectedLecturer"
          class="ml-8 max-w-screen-sm grid gap-8 grid-cols-1"
        >
          <div *ngFor="let questionCat of courses.QuestionAndCategories">
            <label
              class="rounded font-bold text-lg w-full text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            >
              CATEGORY: {{ questionCat.Category }}
            </label>
            <div *ngFor="let question of questionCat.Questions">
              <label
                class="rounded text-sm w-full mt-6 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
              >
                ({{ question.QuestionNumber }}) {{ question.Question }}
              </label>
              <div *ngIf="question.OBJ">
                <div
                  class="bg-white border border-primary rounded-lg w-full p-2 mt-2"
                >
                  <select
                    required
                    [(ngModel)]="option[question.QuestionId]"
                    class="w-full p-2 focus:outline-none"
                    [name]="'options' + question.QuestionNumber"
                  >
                    <option disabled selected value="">Select an option</option>
                    <option
                      *ngFor="let option of question.Options"
                      value="{{ option.OptionId }}"
                    >
                      {{ option.OptionText }}
                    </option>
                  </select>
                </div>
              </div>
              <div *ngIf="!question.OBJ">
                <label class="block">
                  <textarea
                    id="message"
                    rows="4"
                    [(ngModel)]="remark[question.QuestionId]"
                    [required]="true"
                    class="block p-2.5 border border-primary-lighter rounded text-sm w-full mt-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
                    placeholder="Your comment here..."
                  >
                  </textarea>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ml-8 grid gap-8 grid-cols-2">
        <label *ngIf="addedCourses.length != 0" class="block">
          <button
            class="ml-auto sm:ml-5 bg-red-500 text-white rounded-lg py-2 px-4 mr-2 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 mt-2 sm:mt-0"
            type="button"
            (click)="removeCourse()"
          >
            Remove Course
          </button>
        </label>

        <label *ngIf="isSubmitDisabled()" class="block">
          <button
            class="bg-green-500 text-white rounded-lg py-2 px-4 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50"
            type="button"
            (click)="addCourse(selectedCourse)"
          >
            Add Course
          </button>
        </label>
      </div>

      <app-notification-alert
        class="pt-4"
        [data]="notification"
      ></app-notification-alert>

      <nav
        *ngIf="!isSubmitDisabled()"
        class="flex flex-col py-2 gap-4 justify-center"
      >
        <app-button-primary
          type="Submit"
          [disabled]="loading"
          class="mx-auto max-w-screen-sm w-full"
        >
          Submit
        </app-button-primary>
      </nav>
    </form>
  </section>
</app-dashboard-layout>
