<app-dashboard-layout [loading]="loading">
    <div *ngIf="loading" class="loading-spinner">
        Loading...
        <!-- <div class="loader"></div> -->
    </div>

    <div *ngIf="!loading">
        <div class="text-lg text-gray-600 pt-4">
            <label><b>Please see terms and conditions as well as further instructions below</b></label>
        </div>
        <!-- <div [innerHtml]="HallInstructions" class="text-sm text-gray-600 pt-4"></div> -->
        <div [innerHtml]="ProgrammeTypeAccomodationInstructions" class="text-sm text-gray-600 pt-4"></div>
        
        <div>
            <input type="checkbox" id="acknowledge" (change)="toggleAcknowledgment($event)" class="checkbox">
            <label for="acknowledge" class="checkbox-label">I have read and understood the instructions</label>
        </div>
        
        <button (click)="back()" class="btn btn-secondary mr-2">Back</button>
        <button *ngIf="acknowledged" (click)="acknowledgeInstructions()" class="btn btn-primary">Continue</button>
    </div>           
</app-dashboard-layout>