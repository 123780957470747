import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PendingActionsComponent } from "./components/dashboard/pending-actions/pending-actions.component";
import { NewsComponent } from "./components/news/news.component";
import { PaymentIndexComponent } from "./pages/payments/payment-index/payment-index.component";
import { StudentInfoHeaderComponent } from "./components/dashboard/student-info-header/student-info-header.component";
import { CourseInfoComponent } from "./pages/courses/course-info/course-info.component";
import { LoginPageComponent } from "./pages/auth/login-page/login-page.component";
import { CoursesIndexPageComponent } from "./pages/courses/courses-index-page/courses-index-page.component";
import { LoadingIndicatorComponent } from "./components/_partials/loading-indicator/loading-indicator.component";
import { NavigationDrawerComponent } from "./components/_partials/navigation-drawer/navigation-drawer.component";
import { CoursesHistoryPageComponent } from "./pages/courses/courses-history-page/courses-history-page.component";
import { ErrorPageComponent } from "./layouts/error-page/error-page.component";
import { PaymentGenerateComponent } from "./pages/payments/payment-generate/payment-generate.component";
import { PaymentAdviceComponent } from "./pages/payments/payment-advice/payment-advice.component";
import { PaymentReceiptComponent } from "./pages/payments/payment-receipt/payment-receipt.component";
import { DashboardLayoutComponent } from "./layouts/dashboard-layout/dashboard-layout.component";
import { BioDataBasicComponent } from "./pages/bio-data/bio-data-basic/bio-data-basic.component";
import { BioDataMedicalComponent } from "./pages/bio-data/bio-data-medical/bio-data-medical.component";
import { BioDataEducationComponent } from "./pages/bio-data/bio-data-education/bio-data-education.component";
import { CourseRegistrationFormComponent } from "./components/courses/course-registration-form/course-registration-form.component";
import { DialogOverlayComponent } from "./components/_partials/dialog-overlay/dialog-overlay.component";
import { TopNavBarComponent } from "./components/_partials/top-nav-bar/top-nav-bar.component";
import { DashboardIndexComponent } from "./pages/dashboard/dashboard-index/dashboard-index.component";
import { AccountDropdownComponent } from "./components/_partials/account-dropdown/account-dropdown.component";
import { BioDataIndexComponent } from "./pages/bio-data/bio-data-index/bio-data-index.component";
import { BioDataPassportComponent } from "./pages/bio-data/bio-data-passport/bio-data-passport.component";
import { NotificationAlertComponent } from "./components/_reusables/notification-alert/notification-alert.component";
import { DashboardCardComponent } from "./components/_reusables/dashboard-card/dashboard-card.component";
import { NoContentComponent } from "./components/_reusables/no-content/no-content.component";
import { ResultsIndexComponent } from "./pages/results/results-index/results-index.component";
import { ResultsPrintComponent } from "./pages/results/results-print/results-print.component";
import { TimeTableComponent } from "./components/dashboard/time-table/time-table.component";
import { CoursesCurrentComponent } from "./pages/courses/courses-current/courses-current.component";
import { CoursesPrintRegistrationComponent } from "./pages/courses/courses-print-registration/courses-print-registration.component";
import { ButtonPrimaryComponent } from "./components/_reusables/button-primary/button-primary.component";
import { PaymentRemitaComponent } from "./pages/payments/payment-remita/payment-remita.component";
import { DownloadDialogComponent } from "./components/_reusables/download-dialog/download-dialog.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { ForgotPasswordComponent } from "./pages/auth/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/auth/reset-password/reset-password.component";
import { DocumentUploadPageComponent } from "./pages/bio-data/document-upload-page/document-upload-page.component";
import { AdminLoginComponent } from "./pages/auth/admin-login/admin-login.component";
import { NewsIndexComponent } from "./pages/news/news-index/news-index.component";
import { NewsDetailsComponent } from "./pages/news/news-details/news-details.component";
import { ElectionIndexComponent } from "./pages/election/election-index/election-index.component";
import { ElectionRunningComponent } from "./pages/election/election-running/election-running.component";
import { ElectionApplicationComponent } from "./pages/election/election-application/election-application.component";
import { ElectionCandidateApplicationComponent } from "./pages/election/election-candidate-application/election-candidate-application.component";
import { ElectionVoteComponent } from "./pages/election/election-vote/election-vote.component";
import { PaymentTranzgateComponent } from "./pages/payments/payment-tranzgate/payment-tranzgate.component";
import { PaymentCompletedComponent } from "./pages/payments/payment-completed/payment-completed.component";
import { AccommodationIndexComponent } from "./pages/accommodation/accommodation-index/accommodation-index.component";
import { AccommodationApplicationIndexComponent } from "./pages/accommodation/accommodation-application-index/accommodation-application-index.component";
import { AccommodationApplicationRoomsComponent } from "./pages/accommodation/accommodation-application-rooms/accommodation-application-rooms.component";
import { ApplicationIndexComponent } from "./pages/application/application-index/application-index.component";
import { ApplicationReferralComponent } from "./pages/application/application-referral/application-referral.component";
import { ApplicationCatchupComponent } from "./pages/application/application-catchup/application-catchup.component";
import { ApplicationTranscriptComponent } from "./pages/application/application-transcript/application-transcript.component";
import { ApplicationTranscriptCollectionComponent } from "./pages/application/application-transcript-collection/application-transcript-collection.component";
import { ApplicationStudentProfileComponent } from "./pages/application/application-student-profile/application-student-profile.component";
import { ApplicationDefermentComponent } from "./pages/application/application-deferment/application-deferment.component";
import { ApplicationBankInformationComponent } from './pages/application/application-bank-information/application-bank-information.component';
import { ApplicationComplaintsComponent } from './pages/application/application-complaints/application-complaints.component';
import { ApplicationComplaintsSubmitComponent } from './pages/application/application-complaints-submit/application-complaints-submit.component';
import { ApplicationComplaintsViewComponent } from './pages/application/application-complaints-view/application-complaints-view.component';
import { EmailVerifyComponent } from './pages/auth/email-verify/email-verify.component';
import { ApplicationChangeOfProgrammeComponent } from './pages/application/application-change-of-programme/application-change-of-programme.component';
import { AccommodationReservationComponent } from './pages/accommodation/accommodation-reservation/accommodation-reservation.component';
import { AccommodationConfirmationComponent } from './pages/accommodation/accommodation-confirmation/accommodation-confirmation.component';
import { AccommodationPaymentConfirmationComponent } from './pages/accommodation/accommodation-payment-confirmation/accommodation-payment-confirmation.component';
import { MdbCarouselModule } from "mdb-angular-ui-kit/carousel";
import { AppointmentIndexComponent } from './pages/appointment/appointment-index/appointment-index.component';
import { AppointmentNewComponent } from './pages/appointment/appointment-new/appointment-new.component';
import { AppointmentEditComponent } from "./components/_partials/appointment-edit/appointment-edit.component";
import { DashboardPopupComponent } from './components/_partials/dashboard-popup/dashboard-popup.component';
import { ApplicationChangeOfProgrammeIndexComponent } from './pages/application/application-change-of-programme-index/application-change-of-programme-index.component';
import { ApplicationSiwesComponent } from './pages/application/application-siwes/application-siwes.component';
import { ApplicationSiwesViewComponent } from './pages/application/application-siwes-view/application-siwes-view.component';
import { ApplicationStudentAcceptanceComponent } from './pages/application/application-student-acceptance/application-student-acceptance.component';
import { ApplicationStudentAcceptanceSubmitComponent } from './pages/application/application-student-acceptance-submit/application-student-acceptance-submit.component';
import { ApplicationStudentExaminationsComponent } from './pages/application/application-student-examinations/application-student-examinations.component';
import { ApplicationStudentExaminationsModifyComponent } from './pages/application/application-student-examinations-modify/application-student-examinations-modify.component';
import { ApplicationStudentExaminationsConfirmationComponent } from './pages/application/application-student-examinations-confirmation/application-student-examinations-confirmation.component';
import { GeneralDialogComponent } from './components/_reusables/general-dialog/general-dialog.component';
import { AccommodationApplicationStatusComponent } from './pages/accommodation/accommodation-application-status/accommodation-application-status.component';
import { PaymentTranzgateListComponent } from './pages/payments/payment-tranzgate-list/payment-tranzgate-list.component';
import { CourseEvaluationQuestionnaireComponent } from './pages/courses/course-evaluation-questionnaire/course-evaluation-questionnaire.component';
import { AccommodationInstructionsComponent } from './pages/accommodation-instructions/accommodation-instructions.component';
import { StudentResetPasswordComponent } from './pages/auth/student-reset-password/student-reset-password.component';
import { PaymentRemitaSessionsComponent } from './pages/payments/payment-remita-sessions/payment-remita-sessions.component';

@NgModule({
  declarations: [
    AppComponent,
    TopNavBarComponent,
    DialogOverlayComponent,
    DashboardCardComponent,
    ButtonPrimaryComponent,
    DownloadDialogComponent,
    AccountDropdownComponent,
    LoadingIndicatorComponent,
    NavigationDrawerComponent,
    StudentInfoHeaderComponent,
    NotificationAlertComponent,

    TimeTableComponent,
    ErrorPageComponent,
    NoContentComponent,
    PendingActionsComponent,
    DashboardIndexComponent,

    AuthLayoutComponent,
    DashboardLayoutComponent,

    LoginPageComponent,
    AdminLoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    StudentResetPasswordComponent,

    BioDataIndexComponent,
    BioDataBasicComponent,
    BioDataMedicalComponent,
    BioDataPassportComponent,
    BioDataEducationComponent,
    DocumentUploadPageComponent,

    CourseInfoComponent,
    CoursesCurrentComponent,
    CoursesIndexPageComponent,
    CoursesHistoryPageComponent,
    CourseRegistrationFormComponent,
    CoursesPrintRegistrationComponent,

    ResultsIndexComponent,
    ResultsPrintComponent,

    NewsComponent,
    NewsIndexComponent,
    NewsDetailsComponent,

    ElectionVoteComponent,
    ElectionIndexComponent,
    ElectionRunningComponent,
    ElectionApplicationComponent,
    ElectionCandidateApplicationComponent,

    PaymentIndexComponent,
    PaymentRemitaComponent,
    PaymentAdviceComponent,
    PaymentReceiptComponent,
    PaymentGenerateComponent,
    PaymentTranzgateComponent,
    PaymentCompletedComponent,

    AccommodationIndexComponent,
    AccommodationApplicationIndexComponent,
    AccommodationApplicationRoomsComponent,
    AccommodationInstructionsComponent,


    ApplicationIndexComponent,
    ApplicationReferralComponent,
    ApplicationCatchupComponent,
    ApplicationTranscriptComponent,
    ApplicationTranscriptCollectionComponent,
    ApplicationStudentProfileComponent,
    ApplicationDefermentComponent,
    ApplicationBankInformationComponent,
    ApplicationComplaintsComponent,
    ApplicationComplaintsSubmitComponent,
    ApplicationComplaintsViewComponent,
    EmailVerifyComponent,
    ApplicationChangeOfProgrammeComponent,
    AppointmentIndexComponent,
    AppointmentNewComponent, 
    AppointmentEditComponent,   
    AccommodationReservationComponent,
    AccommodationConfirmationComponent,
    AccommodationPaymentConfirmationComponent,
    DashboardPopupComponent,
    ApplicationChangeOfProgrammeIndexComponent,
    ApplicationSiwesComponent,
    ApplicationSiwesViewComponent,
    ApplicationStudentAcceptanceComponent,
    ApplicationStudentAcceptanceSubmitComponent,
    ApplicationStudentExaminationsComponent,
    ApplicationStudentExaminationsModifyComponent,
    ApplicationStudentExaminationsConfirmationComponent,
    GeneralDialogComponent,
    AccommodationApplicationStatusComponent,
    PaymentTranzgateListComponent,
    CourseEvaluationQuestionnaireComponent,
    PaymentRemitaSessionsComponent,
   
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    MdbCarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
