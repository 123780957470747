export const environment = {
  production: true,
  FRONTEND_BASE_URL: "https://studentportal.unilag.edu.ng",
  BASE_URL: "//studentportalbeta.unilag.edu.ng",

  logo: "/assets/logos/unilag.png",
  short_name: "Unilag",
  long_name: "University of Lagos",

  nav_links: {
    home: true,
    biodata: true,
    payment: true,
    course: true,
    result: true,
    appointment: true,
    election: true,
    accommodation: true,
    application: true,
  },

  remita: true,
};
