<app-dashboard-layout [downloader]="downloader">       
    <div class="shadow rounded-md">
        <div *ngIf="loading" class="animate-pulse flex space-x-4 p-4 w-full h-full mx-auto">
          <div class="flex-1 space-y-6 py-1">
            <div class="h-20 bg-slate-200 rounded"></div>
            <div class="space-y-3">              
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
              <div class="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>
        </div>
    </div>
    <div (click)="closeOpen($event)">
        <app-appointment-edit *ngIf="showEdit && !loading" [selectedAppointment]="childData"
            (notify)="handleNotificationFromChild($event)"></app-appointment-edit>
        <section *ngIf="!loading" class="bg-white border rounded-md p-4">
            <section class="font-sans flex flex-col w-full pb-6 gap-1 ">
            <p class="font-semibold text-header text-md md:text-xl">Schedule Appointment</p>
            <p class="font-normal text-standard text-sm md:text-md-lg">Schedule an appointment with a department.</p>
            
            <form ngNativeValidate #scheduleForm="ngForm" (ngSubmit)="scheduleForm.form.valid && submitAppointment()" class="w-full">
                <app-notification-alert class="text-sm md:text-md" [data]="notification"></app-notification-alert>

                <label class="font-normal text-sm md:text-md pt-2 text-standard">Select Appointment</label>
                <select class="md:text-xs block w-full border border-inputborder px-3 py-2 text-gray-700 rounded leading-tight"
                    #appointmentOptions (change)="loadDates(appointmentOptions.value)" required>
                    <option value="" hidden selected disabled>Select appointment</option>
                    <option *ngFor="let appointment of appointments" [value]="appointment.EventID">
                        {{appointment.EventName}}
                    </option>                   
                </select>

                <div>
                    <label class="font-normal text-sm md:text-md pt-2 text-standard">Select Date</label>
                    <input class="w-full md:text-xs block border border-inputborder px-3 py-2 text-gray-700 rounded leading-tight" required type="date"
                        placeholder="dd/mm/yyyy" (change)="loadSessions(appointmentOptions.value)" min="{{MinDate}}" max ={{MaxDate}} [(ngModel)]="selectedDate"/>    
                </div>
                
                <div>
                    <label class="font-normal text-sm md:text-md pt-2 text-standard">Select Session</label>
                    <select [(ngModel)]="selectedSessionID" class="md:text-xs block w-full border border-inputborder px-3 py-2 text-gray-700 rounded leading-tight" required>
                        <option value="" hidden selected disabled>Select Session</option>
                        <option *ngFor="let session of availableSession" [value]="session.SessionID">
                            {{session.SessionID}}
                        </option>                   
                    </select>
                </div>
                                
                <nav class="flex flex-col py-2 gap-4 justify-center text-sm md:text-md md:pt-5">
                    <app-button-primary [disabled]="loading || availableSession.length < 1" type="submit" class=" text-sm md:text-md md:pt-6 mx-auto">
                        SCHEDULE APPOINTMENT
                    </app-button-primary>
                </nav>
            </form>

            <div>
                <p class="font-semibold text-md md:text-xl pb-1 mb-3 mt-3 text-header border-b">My Appointments</p>
                <app-notification-alert [data]="myAppointmentNotification" class="text-sm md:text-md"></app-notification-alert>
                <div *ngIf="!showMyAppointments">
                    <img class="m-auto" src="../../../../assets/images/design.svg" />
                    <p class="font-normal text-sm md:text-md text-center text-standard">You have no appointments</p>
                    <p class="font-normal text-sm md:text-md text-center text-primary pb-5">Your appointments will appear here</p>
                </div>
                                
                <div *ngIf="showMyAppointments" class="overflow-x-auto relative">
                    <div *ngFor="let myAppointment of myAppointments;let i= index" class="md:hidden">
                        <div id="{{'caaction' + i}}" class="w-2/5 text-sm border rounded px-3 bg-neutral-100 actioncall" hidden style="position: relative; left:59%; top: 2px;">
                            <div (click)="PrintDetail(myAppointment)" class="text-lightBlue cursor-pointer py-2">Download Appointment</div>
                            <div *ngIf="myAppointment.CanCancel" (click)="togglePopup(myAppointment)" class="text-lightBlue cursor-pointer py-2">Reschedule</div>
                            <div *ngIf="myAppointment.CanCancel" (click)="cancelAppointment(myAppointment)" class="text-error cursor-pointer py-2">Cancel</div>
                            <div *ngIf="!myAppointment.CanCancel" (click)="cancelAppointment(myAppointment)" class="text-error cursor-pointer py-2">Delete</div>
                        </div>
                        <div class="d-flex p-2 content-center rounded" style="border-left: 5px #007CFF solid; background-color:#DEEEFF;">                             
                            <div class="w-11/12 text-sm md:text-md">                                
                                <div class="truncate">{{myAppointment.Event}}</div>
                                <span class="material-symbols-outlined text-sm mr-1" style="color: #007CFF;"> event </span>
                                <span>{{myAppointment.StringDate}}</span>
                                <span class="material-symbols-outlined text-sm ml-4 mr-1" style="color: #007CFF;"> schedule </span>
                                <span>{{myAppointment.Timestamp}}</span>
                            </div>
                            <span id="{{'caaction' + i}}" class="material-symbols-outlined" (click)="showCallToAction($event)"> more_vert </span>
                        </div>                                               
                    </div>
                    <table class="w-full text-sm text-left text-gray-600 hidden md:block">
                        <thead class="text-xs text-gray-700 bg-primary/5">
                            <tr>
                                <th scope="col" class="py-3 px-6">
                                    Appointment
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    Date
                                </th>                                
                                <th scope="col" class="py-3 px-6">        
                                    Time
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    
                                </th>
                                <th scope="col" class="py-3 px-6">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let myAppointment of myAppointments" class="bg-white border-b" >
                                <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                    {{myAppointment.Event}}
                                </th>
                                <td class="py-4 px-6">
                                    {{myAppointment.StringDate}}
                                </td>
                                <td class="py-4 px-6">
                                    {{myAppointment.Timestamp}}
                                </td>
                                <td *ngIf="myAppointment.CanCancel" (click)="togglePopup(myAppointment)" class="py-4 px-6 text-lightBlue cursor-pointer">
                                    Reschedule
                                </td>
                                <td *ngIf="myAppointment.CanCancel" (click)="cancelAppointment(myAppointment)" class="py-4 px-6 text-error cursor-pointer">
                                    Cancel
                                </td>
                                <td *ngIf="!myAppointment.CanCancel" (click)="cancelAppointment(myAppointment)" class="py-4 px-6 text-error cursor-pointer">
                                    Delete
                                </td>
                                <td (click)="PrintDetail(myAppointment)" class="py-4 px-6 text-lightBlue cursor-pointer">
                                    Download Appointment
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </section>       
        </section>
    </div>    
</app-dashboard-layout>
