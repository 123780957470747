<app-dashboard-layout
  [notDashboard]="true"
  [loading]="loading || skeletonLoader"
>
  <section class="bg-white border rounded-md p-4">
    <header class="border-b flex text-lg w-full pb-6 pb-4 gap-8 md:text-xl">
      Basic Biodata Info
    </header>

    <div
      *ngIf="skeletonLoader"
      class="max-w-screen-md grid pb-4 gap-4 grid-cols-1 md:gap-8 md:grid-cols-2"
    >
      <div
        *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]"
        class="border rounded-md bg-gray-300 h-20 animate-pulse"
      ></div>
    </div>

    <form
      ngNativeValidate
      *ngIf="!skeletonLoader"
      id="biodata-basic"
      class="max-w-screen-md grid gap-8 grid-cols-1"
      (ngSubmit)="updateBiodata()"
    >
      <app-notification-alert [data]="notification"></app-notification-alert>
      <div *ngIf="!isDisabled">
        <div class="grid gap-8 grid-cols-1 md:grid-cols-2">
          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2 block">
              Title *
            </div>
            <div
              class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            >
              <select
                name="NameTitle"
                [(ngModel)]="payload.NameTitle"
                class="w-full p-1 focus:outline-none"
              >
                <option disabled value="">Select option</option>
                <option *ngFor="let item of DTOs.NameTitle">
                  {{ item }}
                </option>
              </select>
            </div>
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2 block">
              Surname *
            </div>
            <input
              disabled
              type="text"
              name="surname"
              placeholder="Surname"
              [ngModel]="payload.LastName"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2">
              First Name *
            </div>
            <input
              disabled
              type="text"
              name="firstName"
              placeholder="First Name"
              [ngModel]="payload.FirstName"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2">
              Middle Name
            </div>
            <input
              disabled
              type="text"
              name="middleName"
              placeholder="Middle Name"
              [ngModel]="payload.MiddleName"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2">
              Marital Status *
            </div>

            <div
              class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            >
              <select
                type="checkbox"
                name="MaritalStatus"
                [(ngModel)]="payload.MaritalStatus"
                class="w-full p-1 focus:outline-none"
              >
                <option disabled value="">Select option</option>
                <option *ngFor="let item of DTOs.MaritalStatus">
                  {{ item }}
                </option>
              </select>
            </div>
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2">
              Maiden Name
            </div>
            <input
              name="MaidenName"
              placeholder="Maiden Name"
              [(ngModel)]="payload.MaidenName"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2 block">
              Religion *
            </div>

            <div
              class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            >
              <select
                name="Religion"
                [(ngModel)]="payload.Religion"
                class="w-full p-1 focus:outline-none"
              >
                <option disabled value="">Select option</option>
                <option *ngFor="let item of DTOs.Religion">
                  {{ item }}
                </option>
              </select>
            </div>
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2">
              Date of Birth *
            </div>
            <input
              disabled
              required
              type="date"
              id="DateOfBirth"
              placeholder="Date of Birth"
              [value]="payload.DateOfBirth.split('T')[0]"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2 block">
              Nationality *
            </div>

            <input
              disabled
              type="text"
              name="Nationality"
              placeholder="Nationality"
              [ngModel]="payload.Nationality"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2 block">
              State *
            </div>

            <input
              *ngIf="payload.StateOfOrigin"
              disabled
              type="text"
              name="StateOfOrigin"
              placeholder="State"
              [ngModel]="payload.StateOfOrigin"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
            <div *ngIf="states">
              <select
                *ngIf="!payload.StateOfOrigin"
                class="border border-grey-darker rounded-lg p-2 focus:outline-none"
                [(ngModel)]="payload.StateOfOrigin"
                (ngModelChange)="onStateChange()"
              >
                <option disabled value="">Select a state</option>
                <option *ngFor="let state of states" [value]="state.StateID">
                  {{ state.Name }}
                </option>
              </select>
            </div>
          </label>
        </div>

        <label class="block">
          <div class="font-medium text-grey-darker text-sm mb-2 block">
            Local Government Area of Origin *
          </div>
          <input
            *ngIf="payload.LocalGovernmentArea"
            disabled
            type="text"
            name="LocalGovernmentArea"
            placeholder="Local Government Area of Origin"
            [ngModel]="payload.LocalGovernmentArea"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
          <select
            *ngIf="!payload.LocalGovernmentArea"
            class="border border-grey-darker rounded-lg p-2 focus:outline-none"
            [(ngModel)]="payload.LocalGovernmentArea"
          >
            <option disabled value="">Select a local government</option>
            <option
              *ngFor="let localGovt of localGovts"
              [value]="localGovt.LocalGovtID"
            >
              {{ localGovt.Name }}
            </option>
          </select>
        </label>

        <label class="block pb-3">
          <div class="font-medium text-grey-darker text-sm mb-2 block">
            Permanent Home Address *
          </div>
          <input
            required
            type="text"
            id="Address"
            placeholder="Permanent Home Address"
            [(ngModel)]="payload.Address"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </label>
      </div>
      <div class="grid gap-8 grid-cols-1 md:grid-cols-2">
        <label class="block">
          <div class="font-medium text-grey-darker text-sm mb-2">Telephone</div>
          <input
            type="text"
            name="Telephone"
            placeholder="Telephone Number"
            [(ngModel)]="payload.Telephone"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </label>

        <label class="block">
          <div class="font-medium text-grey-darker text-sm mb-2">Mobile *</div>
          <input
            disabled
            required
            type="text"
            name="Mobile"
            placeholder="Mobile Number"
            [(ngModel)]="payload.Mobile"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </label>
      </div>

      <label class="block">
        <div class="font-medium text-grey-darker text-sm mb-2 block">
          Email Address *
        </div>
        <div class="grid gap-8 grid-cols-1 md:grid-cols-2">
          <input
            required
            [disabled]="verified"
            type="text"
            name="AlternateEmail"
            placeholder="Email Address"
            [(ngModel)]="payload.AlternateEmail"
            class="block border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
          <a
            *ngIf="!verified"
            (click)="verifyEmail()"
            class="block w-fit bg-primary/90 hover:bg-primary text-white text-sm font-bold py-2 px-4 rounded-full whitespace-nowrap"
          >
            Verify Email
          </a>
          <a
            *ngIf="verified"
            disabled
            class="block w-fit bg-success/90 hover:bg-success text-white text-sm font-bold py-2 px-4 rounded-full whitespace-nowrap"
          >
            Verified
          </a>
        </div>
      </label>
      <div *ngIf="!isDisabled">
        <label class="block pb-3">
          <div class="font-medium text-grey-darker text-sm mb-2 block">
            Department *
          </div>
          <input
            disabled
            type="text"
            name="Department"
            placeholder="Department"
            [(ngModel)]="payload.Department"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </label>

        <label class="block pb-3">
          <div class="font-medium text-grey-darker text-sm mb-2 block">
            Programme *
          </div>
          <input
            disabled
            type="text"
            name="Programme"
            placeholder="Programme"
            [ngModel]="payload.Programme"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </label>

        <div class="grid gap-4 grid-cols-1 md:gap-8 md:grid-cols-2">
          <label class="block pb-3">
            <div class="font-medium text-grey-darker text-sm mb-2">
              Next of Kin *
            </div>
            <input
              required
              type="text"
              name="NextOfKinName"
              placeholder="Next of Kin"
              [(ngModel)]="payload.NextOfKinName"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>

          <label class="block pb-3">
            <div class="font-medium text-grey-darker text-sm mb-2">
              Phone Number of Next of Kin *
            </div>
            <input
              required
              type="text"
              name="NextOfKinTelephoneNumber"
              placeholder="Phone Number of Next of Kin"
              [(ngModel)]="payload.NextOfKinTelephoneNumber"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>
        </div>

        <label class="block pb-3">
          <div class="font-medium text-grey-darker text-sm mb-2">
            Address of Next of Kin *
          </div>
          <input
            required
            type="text"
            name="AddressOfNextOfKin"
            [(ngModel)]="payload.AddressOfNextOfKin"
            placeholder="Address of Next of Kin"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </label>

        <div class="grid gap-4 grid-cols-1 md:gap-8 md:grid-cols-2 pb-3">
          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2">
              Name of Sponsor *
            </div>
            <input
              required
              type="text"
              name="SponsorName"
              [(ngModel)]="payload.SponsorName"
              placeholder="Name of Sponsor"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>

          <label class="block">
            <div class="font-medium text-grey-darker text-sm mb-2">
              Phone Number of Sponsor *
            </div>
            <input
              required
              type="text"
              name="SponsorTelephoneNumber"
              [(ngModel)]="payload.SponsorTelephoneNumber"
              placeholder="Phone Number of Sponsor"
              class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
            />
          </label>
        </div>

        <label class="block pt-3 pb-3">
          <div class="font-medium text-grey-darker text-sm mb-2">
            Address of Sponsor *
          </div>
          <input
            required
            type="text"
            name="SponsorContactAddress"
            placeholder="Address of Sponsor"
            [(ngModel)]="payload.SponsorContactAddress"
            class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
          />
        </label>

        <div>
          <div
            *ngIf="
              !payload.MatricNoAssigned && payload.UseStudentPreviousMatricNo
            "
            class="flex space-x-2"
          >
            <label>
              <input
                [name]="payload.PreviousStudent"
                [id]="payload.PreviousStudent"
                [checked]="payload.PreviousStudent"
                type="checkbox"
                [(ngModel)]="payload.PreviousStudent"
              />
            </label>

            <label class="mb-2"
              >Are you currently or have you ever previously been a student of
              an undergraduate
              <strong>(not Diploma or Foundation)</strong>
              or postgraduate programme in
              <b>
                <span>{{ payload.InstitutionName }} this institution</span>
              </b>
              and been assigned a Matriculation Number?
            </label>
          </div>

          <div
            *ngIf="
              payload.PreviousStudent &&
              !payload.MatricNoAssigned &&
              payload.UseStudentPreviousMatricNo
            "
          >
            <label class="block pt-3 pb-3">
              <div class="font-medium text-grey-darker text-sm mb-2">
                If Yes, What was your previous Matriculation Number? *
              </div>
              <input
                type="text"
                name="PreviousMatricNo"
                placeholder="Previous Matric Number"
                [(ngModel)]="payload.PreviousMatricNo"
                class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
              />
            </label>

            <label class="block">
              <div class="font-medium text-grey-darker text-sm mb-2 block">
                What was your previous Programme? *
              </div>
              <div
                class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
              >
                <select
                  name="PreviousProgramme"
                  [(ngModel)]="payload.PreviousProgramme"
                  class="w-full p-1 focus:outline-none"
                >
                  <option disabled value="">Select option</option>
                  <option
                    [value]="item.ProgrammeID"
                    *ngFor="let item of payload.Programmes"
                  >
                    {{ item.ProgrammeName }}
                  </option>
                </select>
              </div>
            </label>

            <label class="block pt-3 pb-3">
              <div class="font-medium text-grey-darker text-sm mb-2">
                What was your previous Year of Admision (e.g. 2010)? *
              </div>
              <input
                type="number"
                name="PreviousYearOfAdmission"
                placeholder="Previous Year Of Admission"
                [(ngModel)]="payload.PreviousYearOfAdmission"
                class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
              />
            </label>

            <div class="flex space-x-2">
              <label>
                <input
                  [name]="payload.PreviouslyTerminated"
                  [id]="payload.PreviouslyTerminated"
                  [checked]="payload.PreviouslyTerminated"
                  type="checkbox"
                  [(ngModel)]="payload.PreviouslyTerminated"
                />
              </label>

              <label class="mb-2"
                >Have you ever been previously terminated/withdrawn/expelled
                from this institution?</label
              >
            </div>

            <label class="block">
              <div class="font-medium text-grey-darker text-sm mb-2 block">
                If Yes, what programme were you running? *
              </div>
              <div
                class="border border-primary-lighter rounded text-sm w-full py-1 px-2 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
              >
                <select
                  name="PreviouslyTerminatedProgramme"
                  [(ngModel)]="payload.PreviouslyTerminatedProgramme"
                  class="w-full p-1 focus:outline-none"
                >
                  <option disabled value="">Select option</option>
                  <option
                    [value]="item.ProgrammeID"
                    *ngFor="let item of payload.Programmes"
                  >
                    {{ item.ProgrammeName }}
                  </option>
                </select>
              </div>
            </label>

            <label class="block pt-3 pb-3">
              <div class="font-medium text-grey-darker text-sm mb-2">
                What was the reason for the termination/withdrawal/explusion? *
              </div>
              <input
                type="text"
                name="PreviouslyTerminatedReason"
                placeholder="Previously Terminated Reason"
                [(ngModel)]="payload.PreviouslyTerminatedReason"
                class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
              />
            </label>
          </div>
        </div>
      </div>

      <nav class="flex flex-col py-6 gap-4 justify-center">
        <app-button-primary
          type="submit"
          [disabled]="loading"
          class="mx-auto max-w-screen-sm w-full"
        >
          UPDATE BIODATA
        </app-button-primary>

        <app-notification-alert [data]="notification"></app-notification-alert>
      </nav>
    </form>
  </section>
</app-dashboard-layout>
