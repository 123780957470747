<app-dashboard-layout [notDashboard]="true" [loading]="loading || refreshing">
  <header class="text-lg w-full pb-6 gap-8 md:text-xl">
    {{ electionName }}
    <div class="text-sm text-gray-600">Please select your preferred candidates and click submit to vote</div>
  </header>

  <app-notification-alert [data]="notification"></app-notification-alert>

  <section *ngIf="refreshing">
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
  </section>

  <app-no-content *ngIf="!positions.length && !refreshing" caption="No election voter available"> </app-no-content>

  <section *ngIf="positions.length" class="flex flex-col gap-4">
    <div *ngFor="let position of positions"
      class="bg-white rounded-md cursor-pointer border-2 p-4 block group md:p-6 hover:border-primary/50">
      <div class="font-medium text-lg pb-2 pb-6 uppercase group-hover:text-primary">
        {{ position.PositionName }}
      </div>

      <section class="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <button *ngFor="let candidate of position.Candidates"
          (click)="selectCandidate(position.PositionID, candidate.CandidateID)" class="block rounded-md cursor-pointer border-2 p-4 transform duration-200 block group md:p-6 hover:border-primary hover:bg-primary/75 hover:text-white hover:scale-95 {{
            payload[position.PositionID] == candidate.CandidateID ? 'bg-primary/95 border-primary text-white' : 'bg-white '
          }}">
          <div
            class="bg-cover bg-center bg-no-repeat border-white rounded-full cursor-pointer flex flex-col mx-auto bg-blue-200 border-2 h-28 ring-primary ring-1 w-28 group justify-end items-end"
            [style]="
              candidate ? 'background-image: url(data:image/png;base64,' + candidate?.StudentPassportBytes + ')' : 'background-image: url(https://www.gravatar.com/avatar/?d=mp)'
            "></div>

          <div class="font-medium text-center text-sm pt-4 capitalize">
            {{ candidate.CandidateName }}
          </div>
        </button>
      </section>
    </div>

    <nav class="flex flex-col py-6 gap-4 justify-center">
      <app-button-primary [disabled]="loading" class="mx-auto max-w-screen-sm w-full" (click)="submitVotes()"> Submit
        Vote </app-button-primary>
    </nav>
  </section>
</app-dashboard-layout>