import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { authData } from 'src/app/util/auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-edit',
  templateUrl: './appointment-edit.component.html',
  styleUrls: ['./appointment-edit.component.scss']
})
export class AppointmentEditComponent implements OnInit {

  notification = {};
  @Input() selectedAppointment: any;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>(); 
  loading = true;
  readonly httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };
  readonly baseUrl = `${environment.BASE_URL}`;
  availableSession: Array<any> = [];
  selectedSessionID: any;
  
  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.loadSessions();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.selectedAppointment, "changing here")
    this.selectedAppointment = changes.selectedAppointment.currentValue;
    //new Date(changes.selectedDate.currentValue).toISOString().split('T')[0];
  }

  submitAppointment(){
    this.loading = true;    
    //var selectedSession = this.availableSession.find(i => i.selectedSessionID == this.selectedSessionID);
    var payload = {
        eventID: this.selectedAppointment.Event,
        sessionID: this.selectedSessionID,
        isUpdate: true,
        appointmentID: this.selectedAppointment.AppointmentID
    }
    this.httpClient.post(this.baseUrl+'/events/bookeventsessions', JSON.stringify(payload), this.httpOptions).subscribe(
      (res: any) => {
        this.loading = false;
        if(res.Data){      
          this.notification = {
            error: false,
            message: res.Message
          }
        }        
      },
      (err) => {
        this.loading = false;
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
      }
    );
  }

  loadSessions(){    
    this.httpClient.get(this.baseUrl+'/events/availableeventsessions?eventId='+ this.selectedAppointment.Event+'&selectedDate='+ this.selectedAppointment.DefaultTime, this.httpOptions).subscribe(
      (res: any) => {
        if(res.Data)
          this.availableSession = res.Data;
        this.notification = {};
      },
      (err) => {
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
      },
      () => {
        this.loading = false;
      }
    );
  }

  close(){
    this.notify.emit(false);
  }

  
}