import { Time } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventSessionStudentAppointment } from 'src/app/model';
import { authData } from 'src/app/util/auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appointment-new',
  templateUrl: './appointment-new.component.html',
  styleUrls: ['./appointment-new.component.css']
})
export class AppointmentNewComponent implements OnInit {

  MinDate: any;
  MaxDate: any;
  appointments: Array<any> = [];
  myAppointments: Array<EventSessionStudentAppointment> = [];
  showMyAppointments: boolean = false;
  selectedAppointmentEventID: any;
  selectedTime: any;
  selectedDate: any;
  availableSession: Array<any> = [];
  selectedSessionID: any;
  notification = {};
  myAppointmentNotification = {};
  loading = true;
  showEdit: boolean = false;
  childData: any;
  downloader: any = null;
  
  readonly httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };
  readonly baseUrl = `${environment.BASE_URL}`;

  constructor(private httpClient: HttpClient,) { }

  ngOnInit(): void {        
    //this loads data for select appointment dropdown
    this.httpClient.get(this.baseUrl+'/events/appointment', this.httpOptions).subscribe(
      (res: any) => {
        this.appointments = res.Data;
      },
      (err) => {
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
      }      
    );          
    this.loadMyAppointments();   
  }

  loadDates(selectedAppointment: any){
    this.selectedAppointmentEventID = selectedAppointment;
    this.httpClient.get(this.baseUrl+'/events/eventdates?eventId='+ selectedAppointment, this.httpOptions).subscribe(
      (res: any) => {
        if(res.Data)
          this.MinDate = res.Data.AvailableDates[0].substring(0,10);
          this.MaxDate = res.Data.AvailableDates[res.Data.AvailableDates.length - 1].substring(0,10);
        this.notification = {};
      },
      (err) => {
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
      },
    );    
    this.loadSessions(selectedAppointment);
  }
  
  loadSessions(selectedAppointment: any){    
    if ( this.selectedDate !== undefined){
      var selectedDateTime = new Date(this.selectedDate).toISOString();
      //console.log(selectedDateTime)
      this.httpClient.get(this.baseUrl+'/events/availableeventsessions?eventId='+ selectedAppointment+'&selectedDate='+ this.selectedDate, this.httpOptions).subscribe(
        (res: any) => {
          if(res.Data)
            this.availableSession = res.Data;
          this.notification = {};
        },
        (err) => {
          this.notification = {
            error: true,
            message:
              err?.error?.Message || "An error occurred. Please, try again",
          };
        }
      );
    }    
  }

  submitAppointment(){
    this.loading = true;
    var payload = {
        eventID: this.selectedAppointmentEventID,
        sessionID: this.selectedSessionID
    }
    this.httpClient.post(this.baseUrl+'/events/bookeventsessions', JSON.stringify(payload), this.httpOptions).subscribe(
      (res: any) => {     
        if(res.Data){      
          this.notification = {
            error: false,
            message: res.Message
          }
          this.loadMyAppointments();
          this.myAppointmentNotification = {};
        }        
      },
      (err) => {
        this.loading = false;
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
        return;
      },
    );      
  }

  cancelAppointment(appointment: any){
    this.loading = true;
    this.httpClient.delete(this.baseUrl+'/events/canceleventappointment?appointmentId='+appointment.AppointmentID, this.httpOptions).subscribe(
      (res: any) => {
        this.myAppointmentNotification = { error: false, message: res.Message }
        var index = this.myAppointments.indexOf(appointment);
        if (index > -1)
          this.myAppointments.splice(index, 1);
      },
      (err) => {
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
      },
      () =>{
        this.loading = false;
      }
    );    
  }

  PrintDetail(appointment: any){
    this.loading = true;
    this.notification = false;
    this.downloader = {
      loading: true,
    };
    this.httpClient.get(this.baseUrl+'/events/printeventappointmentdetail?appointmentId='+appointment.AppointmentID, this.httpOptions).subscribe({
      next: (res: any) => {
        console.log(res)
        if (res.Success) {
          this.downloader = {
            loading: false,
            name: res.Data.Name,
            url: `data:application/octet-stream;base64,${res.Data.Data}`,
          };
        } else {
          this.downloader = null;
          this.notification = { error: true, message: res.Message };
        }
      },
      error: (err) => {
        this.downloader = null;
        this.notification = err;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  togglePopup(item: any){
    this.showEdit = !this.showEdit;
    this.childData =item;    
  }

  loadMyAppointments(){
    this.httpClient.get(this.baseUrl+'/events/eventappointmentlist', this.httpOptions).subscribe(
      (res: any) => {
        if (this.myAppointments.length > 0)
          this.myAppointments = [];
        this.myAppointments = this.myAppointments.concat(<Array<EventSessionStudentAppointment>>res.Data.UpcomingAppointments, <Array<EventSessionStudentAppointment>>res.Data.PastAppointments);
        this.myAppointments.forEach(element => {
          element.StringDate = new Date( element.AppointmentDate ).toDateString();
          element.Timestamp = new Date(element.AppointmentDate).toLocaleTimeString('en-US');
          //console.log(element.AppointmentDate.toString().substring(0,10))
          element.DefaultTime = new Date(element.AppointmentDate.toString().substring(0,10)+'T' + '00:00:00').toISOString();
          if(new Date( element.AppointmentDate ) >= new Date())
            element.CanCancel = true;
          else
            element.CanCancel = false;
        });
        //console.log(this.myAppointments);

        this.showMyAppointments = this.myAppointments.length > 0 ? true : false;
      },
      (err) => {
        this.notification = {
          error: true,
          message:
            err?.error?.Message || "An error occurred. Please, try again",
        };
      },
      ()=>{
        this.loading = false;
      }
    );
  }

  handleNotificationFromChild(notification: boolean):void{
    this.showEdit = notification;
    this.loadMyAppointments();
  }

  closeOpen(e: any){
    if (e.target.id.includes("caaction"))
      return;
    else{
      const elements = document.querySelectorAll('.actioncall');
      elements.forEach(e => {
        e.setAttribute("hidden", "true")
      });
    }      
  }

  showCallToAction(e: any){
    for(let item of e.target.parentNode.parentNode.childNodes){
      if (item.id == e.target.id){
        item.hidden =false;
        break;
      }
    }
  }
}
