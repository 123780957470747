<app-dashboard-layout>
    <section class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
        <app-dashboard-card
            *ngFor="let link of links"
            [routerLink]="link.slug"
            [title]="link.title"
            [icon]="link.icon"
            [caption]="link.text"
        ></app-dashboard-card>
    </section>
</app-dashboard-layout>