import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-student-examinations-confirmation",
  templateUrl: "./application-student-examinations-confirmation.component.html",
  styleUrls: ["./application-student-examinations-confirmation.component.scss"],
})
export class ApplicationStudentExaminationsConfirmationComponent
  implements OnInit
{
  @ViewChild("goUp", { static: true }) contentPage!: ElementRef;
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  response = {};

  company = "";
  state = "";

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = false;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const _checks = authData("_checks");
    const { EntryCompleted } = _checks;
    if (EntryCompleted) {
      this.notification = {
        message: "You have completed your form and you can no longer edit",
        error: true,
      };
      return;
    }

    const message: string = location.hash.slice(1);
    if (message) {
      this.notification = {
        message: message.replace(/\+/g, " "),
      };
    }
  }

  showUp() {
    this.contentPage.nativeElement.scrollIntoView();
  }

  submitForm() {
    this.loading = true;
    this.notification = {};

    this.payload = {
      Confirmation: true,
      StudentExaminationDTO: null,
    };
    const baseUrl = `${environment.BASE_URL}/biodata/savestudententryexam`;
    this.httpClient.post(baseUrl, this.payload, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(
            `/application#Your+examination+result+has+been+successfully+submitted`
          );
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
    this.showUp();
  }
}
