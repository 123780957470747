import { errorHandler } from "src/app/util/errorHandler";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { authData } from "src/app/util/auth";
import { DataService } from "src/app/http/data.service";

@Component({
  selector: "app-courses-index-page",
  templateUrl: "./courses-index-page.component.html",
})
export class CoursesIndexPageComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dataService: DataService
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  checkPromise = this.dataService.fetchChecks();

  links: Link[] = [];

  loading = false;
  notification = {};
  downloader: any = null;
  refreshing = true;

  ngOnInit(): void {
    const message: string = location.hash.slice(1);

    if (message)
      this.notification = {
        message: message.replace(/\+/g, " "),
      };

    this.checkPromise
      .then((result) => {
        if (result.Success) {
          const { EnableCourseLecturerAssessment } = result.Data;

          this.links.push({
            title: "View Courses",
            slug: "/courses/current",
            icon: "/assets/icons/paper.svg",
            text: "Add a line or two here that explains what this card does",
          });
          this.links.push({
            title: "Courses History",
            slug: "/courses/history",
            icon: "/assets/icons/history.png",
            text: "Add a line or two here that explains what this card does",
          });
          this.links.push({
            title: "Print Course Registration",
            slug: "/courses/print-registration",
            icon: "/assets/icons/printer.svg",
            text: "Add a line or two here that explains what this card does",
          });
          if (EnableCourseLecturerAssessment)
            this.links.push({
              title: "Course Evaluation Questionnaire",
              slug: "/courses/evaluation",
              icon: "/assets/icons/application200.png",
              text: "Add a line or two here that explains what this card does",
            });
        }
        this.refreshing = false;
      })
      .catch((error) => {
        this.refreshing = false;
      });
  }

  printExamDocket() {
    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/courses/generateDocket`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe(
      (res: any) => {
        if (res.Success) {
          this.downloader = {
            loading: false,
            name: res.Data.Name,
            url: "data:application/octet-stream;base64," + res.Data.Data,
          };
        } else {
          this.notification = {
            error: true,
            message: res.Message,
          };
          this.downloader = null;
        }
      },
      (err) => {
        this.downloader = null;
        this.notification = errorHandler(err);
      }
    );
  }
}

interface Link {
  title: string;
  slug: string;
  icon: string;
  text: string;
}