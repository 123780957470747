import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { authData } from 'src/app/util/auth';
import { errorHandler } from 'src/app/util/errorHandler';
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-application-student-examinations",
  templateUrl: "./application-student-examinations.component.html",
  styleUrls: ["./application-student-examinations.component.scss"],
})
export class ApplicationStudentExaminationsComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  _auth = authData();

  payload: any = {};

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/biodata/studententryexams`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }
}
