import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-student-reset-password",
  templateUrl: "./student-reset-password.component.html",
  styleUrls: ["./student-reset-password.component.scss"],
})
export class StudentResetPasswordComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  loading = false;
  httpOptions: any;
  notification = {};
  token: string = "";
  payload = {
    NewPassword: "",
    NewPasswordConfirmation: "",
    token: "",
  };

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: { [x: string]: string }) => {
      this.token = params["token"];
      this.payload.token = this.token; // include token in payload
    });
  }

  resetPassword() {
    this.loading = true;
    console.log(this.payload);
    const baseUrl = `${environment.BASE_URL}/users/resetPasswordViaLink`;
    this.httpClient
      .post(baseUrl, this.payload, this.httpOptions)
      .subscribe({
        next: (res: any) => {
          if (res.Success) {
            this.notification = {
              success: true,
              message: res.Message || "Password reset successful.",
            };
            this.router.navigateByUrl("/");
          } else {
            this.notification = {
              error: true,
              message: res.Message || "An error occurred.",
            };
          }
        },
        error: (err: any) => {
          this.notification = errorHandler(err);
        },
      })
      .add(() => {
        this.loading = false;
      });
  }
}
