import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { authData, saveAuth } from "src/app/util/auth";

@Component({
  selector: "app-course-registration-form",
  templateUrl: "./course-registration-form.component.html",
})
export class CourseRegistrationFormComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  courses: any = [];
  filter: string = "";
  payload: any = [];

  _auth = authData();

  savePayload: any = [];

  loading = false;
  skeletonLoader = false;

  show = false;
  notification: any = { message: "notification?.message", error: true };

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._auth.Token,
    }),
  };

  ngOnInit(): void {}

  performChecks(): void {
    const _check = authData("_checks");

    const {
      LateRegAppLine,
      PassportUploaded,
      AllowRegistration,
      RegistrationClosed,
      RegistrationLocked,
      OutstandingFeesName,
      StudentHasFilledBioData,
      StudentHasFilledMedicalForm,
      RequireStudentToFillMedicalForm,
      OutstandingFeesForRegistration,
      PaidLateRegistrationCollection,
      PassportPhotoRequiredForRegistration,
      // EvaluationQuestionnaireFilled,
    } = _check;

    let message = "";
    if (!AllowRegistration)
      message = "Sorry, registration is not allowed at the moment";
    else if (PassportPhotoRequiredForRegistration && !PassportUploaded)
      message = "Please upload your passport photograph";
    else if (RegistrationLocked)
      message =
        "Your registration has been locked for this session and semester";
    else if (RegistrationClosed) message = "Registration is closed";
    else if (LateRegAppLine && !PaidLateRegistrationCollection)
      message = "You need to pay the late registration fee";
    else if (OutstandingFeesForRegistration)
      message = "Please pay your: " + OutstandingFeesName;
    else if (!StudentHasFilledBioData)
      message = "You haven't filled your bio-data form";
    else if (RequireStudentToFillMedicalForm && !StudentHasFilledMedicalForm)
      message = "You haven't filled your medical form";
    // if (EvaluationQuestionnaireFilled)
    //   message = "You haven't filled the evaluation questionnaire form";

    // Network/alt error handler
    if (!Object.keys(_check).length) message = "An error occurred";

    this.notification = {
      message,
      error: !!message,
    };

    if (!message) this.fetchCourse();
  }

  fetchCourse() {
    this.skeletonLoader = true;

    const cbaseUrl = `${environment.BASE_URL}/courses/currentCourses`;
    this.httpClient.get(cbaseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        const currentCoursesData = res.Data;

        const baseUrl = `${environment.BASE_URL}/courses/allCourses`;
        this.httpClient.get(baseUrl, this.httpOptions).subscribe(
          (res: any) => {
            if (res.Success) {
              this.skeletonLoader = false;
              this.courses = res.Data;

              this.courses.sort((a: any, b: any) =>
                a.Compulsory > b.Compulsory ? -1 : 1
              );

              this.payload = currentCoursesData.Courses.map((course: any) => {
                return course.CourseCode;
              });
              this.savePayload = currentCoursesData.Courses.map(
                (course: any) => {
                  return {
                    CourseCode: course.CourseCode,
                    Compulsory: course.Compulsory,
                  };
                }
              );
            } else {
              this.skeletonLoader = false;
              this.notification = {
                error: true,
                message: res.Message || "An error occurred.",
              };
            }
          },
          (err) => {
            this.skeletonLoader = false;
            this.notification = errorHandler(err);
          }
        );
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }

  toggleForm() {
    if (this.loading) return;

    this.filter = "";
    this.show = !this.show;
    this.notification = {};
    if (this.show) this.performChecks();
  }

  clickCourse(courseCode: string, compulsory: boolean) {
    if (this.payload.includes(courseCode)) {
      this.payload = this.payload.filter((item: any) => item !== courseCode);
      this.savePayload = this.savePayload.filter(
        (item: any) => item.CourseCode !== courseCode
      );
    } else {
      this.payload.push(courseCode);
      this.savePayload.push({ CourseCode: courseCode, Compulsory: compulsory });
    }
  }

  filterCourses(): any {
    return this.courses.filter(
      (course: any) =>
        course.CourseCode.toLowerCase().includes(this.filter.toLowerCase()) ||
        course.CourseTitle.toLowerCase().includes(this.filter.toLowerCase())
    );
  }

  saveCourses() {
    if (!this.payload.length) return;

    this.loading = true;
    this.notification = {};
    const baseUrl = `${environment.BASE_URL}/courses/saveCourses`;
    this.httpClient
      .post(baseUrl, this.savePayload, this.httpOptions)
      .subscribe({
        next: (res: any) => {
          if (res.Success) {
            this.loading = false;
            const { NewMatricNo, NewToken } = res.Data;
            if (NewMatricNo) {
              saveAuth(
                {
                  ...this._auth,
                  NewMatricNumber: NewMatricNo,
                  Token: NewToken,
                },
                "_auth"
              );
            }
            location.href = "/courses?#Courses+registered+successfully";
          } else {
            this.loading = false;
            this.notification = {
              error: true,
              message: res.Message || "An error occurred.",
            };
          }
        },
        error: (err) => {
          this.loading = false;
          this.notification = errorHandler(err);
        },
      });
  }
}
