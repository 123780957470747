import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { authData } from "src/app/util/auth";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-dashboard-popup",
  templateUrl: "./dashboard-popup.component.html",
  styleUrls: ["./dashboard-popup.component.scss"],
})
export class DashboardPopupComponent implements OnInit {
  _auth = authData();
  isEditable = false;
  req: any = {};
  @Output() okClicked = new EventEmitter<string>();

  constructor(private httpClient: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {}

  toggleEditable() {
    this.isEditable = true;
  }

  ok() {
    if (
      !this._auth?.Student.Telephone.trim() ||
      !this._auth?.Student.Mobile.trim() ||
      !this._auth?.Student.AlternateEmail.trim() ||
      !this._auth?.Student.Address.trim() ||
      !this._auth?.Session.trim()
    ) {
      return;
    }

    this.req = {
      Telephone: this._auth?.Student.Telephone,
      Mobile: this._auth?.Student.Mobile,
      AlternateEmail: this._auth?.Student.AlternateEmail,
      Address: this._auth?.Student.Address,
      Session: this._auth?.Session,
    };
    const baseUrl = `${environment.BASE_URL}/biodata/savestudentsessionalpopup`;
    this.httpClient.put(baseUrl, this.req, this.httpOptions).subscribe({
      next: (res: any) => {
        if (!res.Success) {
          this.BioStorage(res.Message);
        } else this.BioStorage("");
      },
      error: (err) => {
        this.BioStorage(err.error.Message);
      },
    });
  }
  BioStorage(bioerr: any) {
    const auth = sessionStorage.getItem("_auth");
    if (auth !== null) {
      const authObj = JSON.parse(auth);
      authObj.ShowBiodataPopup = false;
      sessionStorage.setItem("_auth", JSON.stringify(authObj));
    }
    bioerr ? this.okClicked.emit(bioerr) : this.okClicked.emit();
  }
}
