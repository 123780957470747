<main class="flex flex-col h-screen max-h-screen bg-primary/5 overflow-hidden pb-5">
  <app-loading-indicator *ngIf="loading"></app-loading-indicator>
  <app-top-nav-bar></app-top-nav-bar>

  <section class="flex-grow h-full justify-between flex">
    <nav class="overflow-hidden md:h-full">
      <app-navigation-drawer></app-navigation-drawer>
    </nav>

    <main class="h-full max-h-full flex-1 pb-28 pt-2 pl-4 pr-4 overflow-y-auto md:pb-20">
      <app-student-info-header *ngIf="!notDashboard"></app-student-info-header>
      <p *ngIf="!notDashboard || data" class="text-primary/75 block md:hidden">
        {{data | titlecase}}</p>
      <ng-content></ng-content>
    </main>

    <aside
      class="bg-white border-l min-h-full w-70 overflow-y-auto hidden lg:block"
    > 
      <app-pending-actions></app-pending-actions>
      <app-news></app-news>
    </aside>
  </section>

  <app-download-dialog
    *ngIf="downloader"
    [data]="downloader"
  ></app-download-dialog>
  <app-general-dialog
  *ngIf="dialog" 
  ></app-general-dialog>
</main>
