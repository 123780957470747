import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";
import { authData } from "src/app/util/auth";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-election-application",
  templateUrl: "./election-application.component.html",
})
export class ElectionApplicationComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  refreshing = true;
  elections: any = [];
  notification = {};

  ngOnInit(): void {
    this.refreshing = true;
    const baseUrl = `${environment.BASE_URL}/elections/eligibleApplicationsElections`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.elections = res.Data;
        } else {
          this.notification = { error: true, message: res.Message };
        }
      },
      error: (err) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.refreshing = false;
      },
    });
  }
  electionCandidate(id: any) {
    let electionChecks: any;
    const baseUrl = `${environment.BASE_URL}/elections/electionchecks?electionId=${id}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          electionChecks = res.Data;
          if (electionChecks.EligbleSchoolResultBasedOnElectionParameters)
            this.notification = {
              error: true,
              message:
                "Your current level is below the required. You are not eligible",
            };
          else if (electionChecks.EligbleCGPA)
            this.notification = {
              error: true,
              message: "Your CGPA is below the required. You are not eligible",
            };
          else if (electionChecks.HasOutstandingCourse)
            this.notification = {
              error: true,
              message: "Students with carryovers are not eligible",
            };
          else if (electionChecks.HasPreviouslyAttendedTertiaryInstitution)
            this.notification = {
              error: true,
              message: "You have a previous degree. You are not eligible",
            };
          else if (electionChecks.HasExtraYears)
            this.notification = {
              error: true,
              message: "Extra-year students are not eligible",
            };
          else if (electionChecks.HasElectionStarted)
            this.notification = {
              error: true,
              message: "Election has Started",
            };
          else if (electionChecks.IsElectionRunning)
            this.notification = {
              error: true,
              message: "Election is running",
            };
          else if (electionChecks.IsElectionCancelled)
            this.notification = {
              error: true,
              message: "Election is Cancelled",
            };
          else this.router.navigateByUrl(`/election/application/${id}`);
        } else {
          this.notification = { error: true, message: res.Message };
        }
      },
      error: (err) => {
        this.notification = errorHandler(err);
      },
    });
  }
}
