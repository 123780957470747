import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-payment-tranzgate-list",
  templateUrl: "./payment-tranzgate-list.component.html",
  styleUrls: ["./payment-tranzgate-list.component.scss"],
})
export class PaymentTranzgateListComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient
  ) {}
  session = "";
  sessions: any = [];
  notification: any = {};

  payload: any;
  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.session = params.id;
      const baseUrl = `${environment.BASE_URL}/payments/tranzgatefeeslist?session=${this.session}`;
      this.httpClient.get(baseUrl, this.httpOptions).subscribe({
        next: (res: any) => {
          if (res.Success) {
            this.payload = res.Data;
          } else {
            this.notification = errorHandler(res);
          }
        },
        error: (err: any) => {
          this.notification = errorHandler(err);
        },
        complete: () => {
          this.loading = false;
          this.skeletonLoader = false;
        },
      });
    });
  }

  payOnTranzgate(req: any) {
    if (!req) return;

    this.loading = true;
    this.notification = {};

    const callBackUrl = `${environment.BASE_URL}/payments/RedirectFromTrazgate`;
    const baseUrl = `${environment.BASE_URL}/payments/payWithTranzgate?session=${this.session}&tranzgateClientId=${req.TranzgateClientId}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          const { ResponseText, StudentFullName } = res.Data;
          let URL = "https://pay.tranzgate.com.ng";
          URL += `/MakePayment.aspx?id=${ResponseText}`;
          URL += `&allowupdate=1&allowupdateamount=1`;
          URL += `&customername=${StudentFullName}`;
          URL += `&returnurl=${encodeURIComponent(callBackUrl)}`;

          location.href = URL;
        } else {
          this.notification = errorHandler(res);
        }
      },
      error: (err: any) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
