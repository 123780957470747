<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <app-notification-alert [data]="notification"></app-notification-alert>
    <section class="bg-white border rounded-md p-4">
        <div *ngIf="!skeletonLoader" class="flex justify-between">
            <header class="border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
                My Biodata:
            </header>
            <app-button-primary (click)="printSiwesForm()" [disabled]="loading">
                Print SIWES Form
            </app-button-primary>
        </div>
        

        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <div *ngIf="!skeletonLoader" class="overflow-x-auto relative shadow-md sm:rounded-lg">
            <div class="grid grid-cols-2 gap-0">
                <table class="w-full text-sm text-left">
                    <thead>
                        <ng-container *ngFor="let field of fields">
                            <tr>
                                <th scope="col" class="border uppercase border-r-2 border-primary-500 text-sm text-primary/90 py-3
                                    px-6 w-32
                                    h-20">
                                    {{ field }}:
                                </th>
                            </tr>
                        </ng-container>
                    </thead>
                </table>

                <table class="w-full text-sm text-left">
                    <thead>
                        <ng-container *ngFor="let key of getObjectKeys(payload)">
                            <tr>
                                <td class="border border-l-2 border-primary-500 text-sm text-black py-3 px-6 w-32 h-20">
                                    {{payload[key]}}
                                </td>
                            </tr>
                        </ng-container>
                    </thead>
                </table>
            </div>

        </div>
    </section>
</app-dashboard-layout>