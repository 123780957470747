<app-dashboard-layout [loading]="loading">
  <app-notification-alert [data]="notification"></app-notification-alert>

  <section class="bg-white border rounded-md p-4">
    <header class="border-b flex mb-6 w-full pb-1 gap-4 justify-between">
      <h2 class="font-medium whitespace-nowrap uppercase">Courses History</h2>
    </header>

    <ng-template [ngIf]="loading" [ngIfElse]="notLoading">
      <div
        *ngFor="let i of [1, 2, 3, 4, 5, 6]"
        class="border rounded-md bg-gray-300 h-12 mb-4 animate-pulse"
      ></div>
    </ng-template>

    <ng-template #notLoading>
      <app-no-content
        *ngIf="noContentFound || notification.error"
        caption="No courses to show."
      >
      </app-no-content>

      <div *ngFor="let session of sessions" class="border-b py-2">
        <nav
          (click)="accordionClicked(session.name)"
          class="cursor-pointer flex pb-2 gap-6 items-center justify-between group hover:text-primary"
        >
          <div class="flex text-sm gap-6 items-center">
            <div class="font-medium">
              {{ session.name }} (Year {{ session?.courses[0]?.Year }})
            </div>
            <div class="text-gray-500 group-hover:text-primary">
              {{ session?.courses?.length }} Courses
            </div>
            <div class="text-gray-500 group-hover:text-primary">
              {{ getTotalUnits(session?.courses) }}
              Units
            </div>
          </div>

          <div
            [ngClass]="
              session == selectedSession
                ? 'text-primary text-lg material-icons'
                : 'text-gray-500 text-lg material-icons'
            "
          >
            expand_more
          </div>
        </nav>

        <section
          *ngIf="session.name === selectedSession"
          class="grid gap-4 :gap-6 grid-cols-1 md:grid-cols-2"
        >
          <div
            *ngFor="let courses of session.courses"
            class="rounded-md border-2 p-4 transform duration-200 group hover:border-primary hover:bg-primary/5 hover:scale-105"
          >
            <div
              class="font-medium text-base pb-4 capitalize group-hover:text-primary"
            >
              {{ courses?.CourseTitle }}
            </div>
            <section class="grid grid-cols-1 md:grid-cols-2">
              <div
                class="flex text-xs text-gray-500 gap-4 uppercase hover:border-primary"
              >
                <div class="whitespace-nowrap">{{ courses?.CourseCode }}</div>
                <div class="whitespace-nowrap">
                  {{ courses?.CourseUnits }} UNITS
                </div>
                <!-- <div class="whitespace-nowrap">
                  {{ courses?.Compulsory ? "Compulsory" : "Elective" }}
                </div> -->
              </div>
            </section>
          </div>
        </section>
      </div>
    </ng-template>
  </section>
</app-dashboard-layout>
