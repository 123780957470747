<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <div #goUp></div>
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-4 gap-8 md:text-xl">
            SIWES Form
        </header>
        <app-notification-alert class="pt-4" [data]="notification"></app-notification-alert>
        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <form ngNativeValidate *ngIf="!skeletonLoader" id="referral-application"
            class="max-w-screen-sm grid gap-8 grid-cols-1 " (ngSubmit)="submitForm()">
            <div class="grid gap-8 grid-cols-1">
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Company Name:*
                    </div>
                    <div class="py-6">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select [(ngModel)]="company" required class="w-full p-2 focus:outline-none" name="company">
                                <option disabled value="">Select a company</option>
                                <option *ngFor="let company of payload.OtherCompaniesDTO" value="{{company.CompanyID}}">
                                    {{ company.CompanyName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Company Address:*
                    </div>
                    <textarea id="companyaddress" rows="4" [(ngModel)]="payload.CompanyAddress" [required]="true" class="block p-2.5 border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700
                        appearance-none focus:outline-none focus-within:border-primary-light"
                        placeholder="Write your company address here...">
                    </textarea>
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Company Contact Person:*
                    </div>
                    <input required type="text" name="contactperson" [(ngModel)]="payload.ContactPerson"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Company Contact Phone Number:*
                    </div>
                    <input required type="text" name="contactpersonphonenumber"
                        [(ngModel)]="payload.ContactPersonPhoneNumber"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Company Contact Email Address:*
                    </div>
                    <input required type="text" name="contactpersonemail" [(ngModel)]="payload.ContactPersonEmail"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Company Supervisor:*
                    </div>
                    <input required type="text" name="supervisor" [(ngModel)]="payload.Supervisor"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Company Supervisor Phone Number:*
                    </div>
                    <input required type="text" name="supervisorphonenumber" [(ngModel)]="payload.SupervisorPhoneNumber"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Company Supervisor Email Address:*
                    </div>
                    <input required type="text" name="supervisoremail" [(ngModel)]="payload.SupervisorEmail"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2">
                        Start Date:*
                    </div>
                    <input required type="date" id="startdate" placeholder="Start Date" [(ngModel)]="payload.StartDate"
                        [value]="payload.StartDate ? payload.StartDate.split('T')[0] : ''"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2">
                        End Date:*
                    </div>
                    <input required type="date" id="enddate" placeholder="End Date" [(ngModel)]="payload.EndDate"
                        [value]="payload.EndDate ? payload.EndDate.split('T')[0] : ''"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        State:*
                    </div>
                    <div class="py-6">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select [(ngModel)]="state" required class="w-full p-2 focus:outline-none" name="state">
                                <option disabled value="">Select a state</option>
                                <option *ngFor="let state of payload.StatesDTO" value="{{state.StateID}}">
                                    {{ state.Name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>
                <label class="block">
                    <input required type="checkbox" name="confirmation" />
                    <label class="ml-3">I hereby confirm that the information provided above is valid and
                        accurate.</label>
                </label>
            </div>
            <nav class="flex flex-col py-2 gap-4 justify-center">
                <app-button-primary type="submit" [disabled]="loading" class="mx-auto max-w-screen-sm w-full">
                    Submit
                </app-button-primary>
            </nav>
        </form>
    </section>
</app-dashboard-layout>