import { ApplicationCatchupComponent } from "./pages/application/application-catchup/application-catchup.component";
import { ApplicationIndexComponent } from "./pages/application/application-index/application-index.component";
import { AccommodationApplicationRoomsComponent } from "./pages/accommodation/accommodation-application-rooms/accommodation-application-rooms.component";
import { PaymentCompletedComponent } from "./pages/payments/payment-completed/payment-completed.component";
import { PaymentTranzgateComponent } from "./pages/payments/payment-tranzgate/payment-tranzgate.component";
import { ElectionCandidateApplicationComponent } from "./pages/election/election-candidate-application/election-candidate-application.component";
import { ElectionVoteComponent } from "./pages/election/election-vote/election-vote.component";
import { NewsDetailsComponent } from "./pages/news/news-details/news-details.component";
import { NewsIndexComponent } from "./pages/news/news-index/news-index.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ErrorPageComponent } from "./layouts/error-page/error-page.component";
import { DashboardIndexComponent } from "./pages/dashboard/dashboard-index/dashboard-index.component";

import { AdminLoginComponent } from "./pages/auth/admin-login/admin-login.component";
import { ResetPasswordComponent } from "./pages/auth/reset-password/reset-password.component";
import { LoginPageComponent } from "./pages/auth/login-page/login-page.component";
import { ForgotPasswordComponent } from "./pages/auth/forgot-password/forgot-password.component";

import { DocumentUploadPageComponent } from "./pages/bio-data/document-upload-page/document-upload-page.component";
import { CoursesPrintRegistrationComponent } from "./pages/courses/courses-print-registration/courses-print-registration.component";

import { BioDataIndexComponent } from "./pages/bio-data/bio-data-index/bio-data-index.component";
import { BioDataEducationComponent } from "./pages/bio-data/bio-data-education/bio-data-education.component";
import { BioDataMedicalComponent } from "./pages/bio-data/bio-data-medical/bio-data-medical.component";
import { BioDataBasicComponent } from "./pages/bio-data/bio-data-basic/bio-data-basic.component";

import { CourseInfoComponent } from "./pages/courses/course-info/course-info.component";
import { CoursesIndexPageComponent } from "./pages/courses/courses-index-page/courses-index-page.component";
import { CoursesHistoryPageComponent } from "./pages/courses/courses-history-page/courses-history-page.component";

import { PaymentIndexComponent } from "./pages/payments/payment-index/payment-index.component";
import { PaymentAdviceComponent } from "./pages/payments/payment-advice/payment-advice.component";
import { PaymentGenerateComponent } from "./pages/payments/payment-generate/payment-generate.component";
import { PaymentReceiptComponent } from "./pages/payments/payment-receipt/payment-receipt.component";
import { BioDataPassportComponent } from "./pages/bio-data/bio-data-passport/bio-data-passport.component";
import { CoursesCurrentComponent } from "./pages/courses/courses-current/courses-current.component";
import { PaymentRemitaComponent } from "./pages/payments/payment-remita/payment-remita.component";

import { ResultsPrintComponent } from "./pages/results/results-print/results-print.component";
import { ResultsIndexComponent } from "./pages/results/results-index/results-index.component";

import { ElectionIndexComponent } from "./pages/election/election-index/election-index.component";
import { ElectionApplicationComponent } from "./pages/election/election-application/election-application.component";
import { ElectionRunningComponent } from "./pages/election/election-running/election-running.component";

import { AccommodationIndexComponent } from "./pages/accommodation/accommodation-index/accommodation-index.component";
import { AccommodationApplicationIndexComponent } from "./pages/accommodation/accommodation-application-index/accommodation-application-index.component";

import { ApplicationReferralComponent } from "./pages/application/application-referral/application-referral.component";
import { ApplicationTranscriptComponent } from "./pages/application/application-transcript/application-transcript.component";
import { ApplicationTranscriptCollectionComponent } from "./pages/application/application-transcript-collection/application-transcript-collection.component";
import { ApplicationStudentProfileComponent } from "./pages/application/application-student-profile/application-student-profile.component";
import { ApplicationDefermentComponent } from "./pages/application/application-deferment/application-deferment.component";
import { ApplicationBankInformationComponent } from "./pages/application/application-bank-information/application-bank-information.component";
import { ApplicationComplaintsComponent } from "./pages/application/application-complaints/application-complaints.component";
import { ApplicationComplaintsSubmitComponent } from "./pages/application/application-complaints-submit/application-complaints-submit.component";
import { ApplicationComplaintsViewComponent } from "./pages/application/application-complaints-view/application-complaints-view.component";
import { EmailVerifyComponent } from "./pages/auth/email-verify/email-verify.component";
import { ApplicationChangeOfProgrammeComponent } from "./pages/application/application-change-of-programme/application-change-of-programme.component";
import { AccommodationReservationComponent } from "./pages/accommodation/accommodation-reservation/accommodation-reservation.component";
import { AccommodationConfirmationComponent } from "./pages/accommodation/accommodation-confirmation/accommodation-confirmation.component";
import { AccommodationPaymentConfirmationComponent } from "./pages/accommodation/accommodation-payment-confirmation/accommodation-payment-confirmation.component";
import { AppointmentIndexComponent } from "./pages/appointment/appointment-index/appointment-index.component";
import { AppointmentNewComponent } from "./pages/appointment/appointment-new/appointment-new.component";
import { ApplicationChangeOfProgrammeIndexComponent } from "./pages/application/application-change-of-programme-index/application-change-of-programme-index.component";
import { ApplicationSiwesComponent } from "./pages/application/application-siwes/application-siwes.component";
import { ApplicationSiwesViewComponent } from "./pages/application/application-siwes-view/application-siwes-view.component";
import { ApplicationStudentAcceptanceComponent } from "./pages/application/application-student-acceptance/application-student-acceptance.component";
import { ApplicationStudentAcceptanceSubmitComponent } from "./pages/application/application-student-acceptance-submit/application-student-acceptance-submit.component";
import { ApplicationStudentExaminationsComponent } from "./pages/application/application-student-examinations/application-student-examinations.component";
import { ApplicationStudentExaminationsModifyComponent } from "./pages/application/application-student-examinations-modify/application-student-examinations-modify.component";
import { ApplicationStudentExaminationsConfirmationComponent } from "./pages/application/application-student-examinations-confirmation/application-student-examinations-confirmation.component";
import { AccommodationApplicationStatusComponent } from "./pages/accommodation/accommodation-application-status/accommodation-application-status.component";
import { PaymentTranzgateListComponent } from "./pages/payments/payment-tranzgate-list/payment-tranzgate-list.component";
import { CourseEvaluationQuestionnaireComponent } from "./pages/courses/course-evaluation-questionnaire/course-evaluation-questionnaire.component";
import { AccommodationInstructionsComponent } from "./pages/accommodation-instructions/accommodation-instructions.component";
import { StudentResetPasswordComponent } from "./pages/auth/student-reset-password/student-reset-password.component";
import { PaymentRemitaSessionsComponent } from "./pages/payments/payment-remita-sessions/payment-remita-sessions.component";

const routes: Routes = [
  {
    path: "",
    component: DashboardIndexComponent,
    data: { title: "Dashboard" },
  },
  {
    path: "login",
    component: LoginPageComponent,
    data: { title: "Login" },
  },
  {
    path: "admin",
    component: AdminLoginComponent,
    data: { title: "Admin Login" },
  },
  {
    path: "verify",
    component: EmailVerifyComponent,
    data: { title: "Verify Email" },
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    data: { title: "Forgot Password" },
  },
  {
    path: "student-reset-password",
    component: StudentResetPasswordComponent,
    data: { title: "Reset Password" },
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: { title: "Reset Password" },
  },
  {
    path: "courses",
    data: { title: "Courses" },
    children: [
      {
        path: "",
        component: CoursesIndexPageComponent,
      },
      {
        path: "current",
        component: CoursesCurrentComponent,
      },
      {
        path: "history",
        component: CoursesHistoryPageComponent,
      },
      {
        path: "print-registration",
        component: CoursesPrintRegistrationComponent,
      },
      {
        path: "evaluation",
        component: CourseEvaluationQuestionnaireComponent,
      },
      {
        path: ":id",
        component: CourseInfoComponent,
      },
    ],
  },
  {
    path: "payments",
    data: { title: "Payments" },
    children: [
      {
        path: "",
        component: PaymentIndexComponent,
      },
      {
        path: "advice",
        component: PaymentAdviceComponent,
      },
      {
        path: "remita",
        children: [
          {
            path: "",
            component: PaymentRemitaSessionsComponent,
          },
          {
            path: ":id",
            component: PaymentRemitaComponent,
          },
        ],
      },
      {
        path: "tranzgate",
        children: [
          {
            path: "",
            component: PaymentTranzgateComponent,
          },
          {
            path: ":id",
            component: PaymentTranzgateListComponent,
          },
        ],
      },
      {
        path: "generate",
        component: PaymentGenerateComponent,
      },
      {
        path: "receipt",
        component: PaymentReceiptComponent,
      },
      {
        path: "completed",
        component: PaymentCompletedComponent,
      },
    ],
  },
  {
    path: "bio-data",
    children: [
      {
        path: "",
        component: BioDataIndexComponent,
      },
      {
        path: "basic",
        component: BioDataBasicComponent,
      },
      {
        path: "medical",
        component: BioDataMedicalComponent,
      },
      {
        path: "education",
        component: BioDataEducationComponent,
      },
      {
        path: "passport",
        component: BioDataPassportComponent,
      },
      {
        path: "documents",
        component: DocumentUploadPageComponent,
      },
    ],
  },
  {
    path: "results",
    children: [
      {
        path: "",
        component: ResultsIndexComponent,
      },
      {
        path: "print",
        component: ResultsPrintComponent,
      },
    ],
  },
  {
    path: "election",
    children: [
      {
        path: "",
        component: ElectionIndexComponent,
      },
      {
        path: "vote",
        children: [
          {
            path: "",
            component: ElectionRunningComponent,
          },
          {
            path: ":id",
            component: ElectionVoteComponent,
          },
        ],
      },
      {
        path: "application",
        children: [
          {
            path: "",
            component: ElectionApplicationComponent,
          },
          {
            path: ":id",
            component: ElectionCandidateApplicationComponent,
          },
        ],
      },
    ],
  },
  {
    path: "news",
    children: [
      {
        path: "",
        component: NewsIndexComponent,
      },
      {
        path: ":id",
        component: NewsDetailsComponent,
      },
    ],
  },
  {
    path: "accommodation",
    children: [
      {
        path: "",
        component: AccommodationIndexComponent,
      },
      {
        path: "application",
        component: AccommodationApplicationIndexComponent,
      },
      {
        path: "instructions",
        component: AccommodationInstructionsComponent,
      },
      {
        path: "application/:id",
        component: AccommodationApplicationRoomsComponent,
      },
      {
        path: "reservation",
        component: AccommodationReservationComponent,
      },
      {
        path: "confirmation",
        component: AccommodationConfirmationComponent,
      },
      {
        path: "payment",
        component: AccommodationPaymentConfirmationComponent,
      },
      {
        path: "status",
        component: AccommodationApplicationStatusComponent,
      },
    ],
  },
  {
    path: "application",
    children: [
      {
        path: "",
        component: ApplicationIndexComponent,
      },
      {
        path: "catchup",
        component: ApplicationCatchupComponent,
      },
      {
        path: "referral",
        component: ApplicationReferralComponent,
      },
      {
        path: "transcript",
        children: [
          {
            path: "",
            component: ApplicationTranscriptComponent,
          },
          {
            path: ":id",
            component: ApplicationTranscriptCollectionComponent,
          },
        ],
      },
      {
        path: "profile",
        component: ApplicationStudentProfileComponent,
      },
      {
        path: "deferment",
        component: ApplicationDefermentComponent,
      },
      {
        path: "bankinfo",
        component: ApplicationBankInformationComponent,
      },
      {
        path: "siwes",
        component: ApplicationSiwesComponent,
      },
      {
        path: "siwesview",
        component: ApplicationSiwesViewComponent,
      },
      {
        path: "complaints",
        children: [
          {
            path: "",
            component: ApplicationComplaintsComponent,
          },
          {
            path: "submit",
            component: ApplicationComplaintsSubmitComponent,
          },
          {
            path: "view",
            component: ApplicationComplaintsViewComponent,
          },
        ],
      },
      {
        path: "acceptance",
        children: [
          {
            path: "",
            component: ApplicationStudentAcceptanceComponent,
          },
          {
            path: "submit",
            component: ApplicationStudentAcceptanceSubmitComponent,
          },
        ],
      },
      {
        path: "changeofprogramme",
        children: [
          {
            path: "",
            component: ApplicationChangeOfProgrammeIndexComponent,
          },
          {
            path: "submit",
            component: ApplicationChangeOfProgrammeComponent,
          },
        ],
      },
      {
        path: "examination",
        children: [
          {
            path: "",
            component: ApplicationStudentExaminationsComponent,
          },
          {
            path: "confirmation",
            component: ApplicationStudentExaminationsConfirmationComponent,
          },
          {
            path: "modify",
            component: ApplicationStudentExaminationsModifyComponent,
          },
        ],
      },
    ],
  },
  {
    path: "appointment",
    children: [
      {
        path: "",
        component: AppointmentIndexComponent,
      },
      {
        path: "new",
        component: AppointmentNewComponent,
      },
    ],
  },
  {
    path: "**",
    component: ErrorPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
