import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-siwes-view",
  templateUrl: "./application-siwes-view.component.html",
  styleUrls: ["./application-siwes-view.component.scss"],
})
export class ApplicationSiwesViewComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  response = {};

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  fields: string[] = [
    "Matric Number",
    "Title",
    "Surname",
    "First Name",
    "Middle Name",
    "Marital Status",
    "Maiden Name",
    "Gender",
    "Religion",
    "Date Of Birth",
    "Nationality",
    "State of Origin",
    "Local Government",
    "Permanent Home Address",
    "Telephone Number",
    "G.S.M. Number",
    "E-mail Address",
    "Department",
    "Programme",
    "Bank Name",
    "Account Name",
    "Account Number",
    "Sort Code",
    "Company Name",
    "Company address",
    "Session",
    "Contact Person",
    "Contact Person Number",
    "Contact Person Email",
    "Supervisor",
    "Supervisor Email",
    "Supervisor Phone Number",
  ];

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/biodata/viewsiwesform`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
          console.log(this.payload);
        } else {
          this.notification = errorHandler(res);
        }
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });

    const message: string = location.hash.slice(1);
    if (message) {
      this.notification = {
        error: true,
        message: message.replace(/\+/g, " "),
      };
    }
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  printSiwesForm() {
    this.loading = true;
    this.notification = {};

    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/biodata/printsiwesform`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.downloader = {
            loading: false,
            name: res.Data.Name,
            url: `data:application/octet-stream;base64,${res.Data.Data}`,
          };
        } else {
          this.downloader = null;
          this.notification = { error: true, message: res.Message };
          this.loading = false;
        }
      },
      error: (err) => {
        this.downloader = null;
        this.notification = errorHandler(err);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}
