import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment-index',
  templateUrl: './appointment-index.component.html',
  styleUrls: ['./appointment-index.component.scss']
})
export class AppointmentIndexComponent implements OnInit {
//#region Fields
  links = [
    {
      title: "New Appointment",
      slug: "/appointment/new",
      icon: "/assets/icons/icons8-appointment-64.png",
      text: "Book new appointments",
    }    
  ];
//#endregion
  constructor() { }

  ngOnInit(): void {
  }

}
