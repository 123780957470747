<app-auth-layout [loading]="loading">
    <form
      ngNativeValidate
      (ngSubmit)="resetPassword()"
      class="flex flex-col h-full p-8 md:p-12"
    >
      <h1
        class="font-bold py- text-center text-lg pb-4 uppercase md:text-xl md:pb-8 lg:text-2xl"
      >
        Reset Password
      </h1>
  
      <app-notification-alert [data]="notification"></app-notification-alert>
  
      <label class="py-4 block">
        <div class="font-bold text-grey-darker text-sm mb-2 block">
          New Password
        </div>
        <input
          required
          type="password"
          id="NewPassword"
          [(ngModel)]="payload.NewPassword"
          placeholder="New Password"
          class="border rounded bg-gray-50 text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
        />
      </label>
  
      <label class="block">
        <div class="font-bold text-grey-darker text-sm mb-2 block">
          New Password Confirmation
        </div>
        <input
          required
          type="password"
          id="NewPasswordConfirmation"
          [(ngModel)]="payload.NewPasswordConfirmation"
          placeholder="New Password Confirmation"
          class="border rounded bg-gray-50 text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light"
        />
      </label>
  
      <div class="py-8">
        <app-button-primary type="submit" [disabled]="loading" class="w-full">
          Change Password
        </app-button-primary>
      </div>
    </form>
  </app-auth-layout>
  